import { default as React } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import locale from '@/common/utils/locale';
import {
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    Grid,
    Paper,
    Select,
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    Chip,
    Switch,
    Modal,
    AppBar,
    Toolbar,
    IconButton,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { default as ChipInput } from 'material-ui-chip-input';
import { NewEndpointContainer } from './NewEndpointContainer';
import { separate, sequenceType, dateSeparate } from '@/user/constants/endpoint-setting';
import { Close } from '@material-ui/icons';
import Wordbook from '@/user/components/common/endpoints/wordbook/Wordbook';
import { getSplitNewLineString } from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
        },
        heading: {
            float: 'left',
            fontSize: '14px',
            color: '#666666',
        },
        fileNameErrorGrid: {
            marginBottom: theme.spacing.unit * 2,
            overflowWrap: 'break-word',
            wordBreak: 'keep-all',
        },
        example: {
            float: 'left',
            fontSize: '14px',
            color: '#333333',
            width: theme.spacing.unit * 81,
            overflow: 'auto',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
            paddingBottom: '5px',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
        ruleContainer: {
            float: 'left',
            width: theme.spacing.unit * 81,
            minHeight: theme.spacing.unit * 7.5,
            borderRadius: '4px',
            border: '2px solid #bbbbbb',
            paddingTop: theme.spacing.unit,
            paddingLeft: theme.spacing.unit,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            '&& p': {
                marginTop: '15px',
                marginBottom: '15px',
            },
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        extensionContainer: {
            float: 'left',
            width: theme.spacing.unit * 81,
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        extension: {
            float: 'right',
            size: '14px',
        },
        formControl: {
            float: 'left',
            minWidth: theme.spacing.unit * 38,
            minHeight: theme.spacing.unit * 6.5,
            borderRadius: '4px',
            marginRight: '2%',
            '&& select': {
                paddingTop: theme.spacing.unit * 2,
                paddingBottom: theme.spacing.unit * 2.5,
                height: theme.spacing.unit * 3,
            },
            [theme.breakpoints.down('md')]: {
                width: '48%',
            },
            [theme.breakpoints.down('xs')]: {
                float: 'left',
                width: '100%',
                minWidth: '0',
            },
            [theme.breakpoints.down('sm')]: {
                float: 'left',
                width: '100%',
                minWidth: theme.spacing.unit * 30,
            },
        },
        select: {
            size: '15px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        buttonFrame: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: `${theme.palette.secondary.main}`,
            borderRadius: 6,
            height: theme.spacing.unit * 6,
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ff7300',
                    backgroundColor: '#ffffff',
                },
            },
            '&:disabled': {
                backgroundColor: '#ff7300',
                color: '#ffffff',
            },
        },
        formControlLabel: {
            float: 'left',
            marginTop: theme.spacing.unit * 2.5,
            marginBottom: theme.spacing.unit * 2.5,
        },
        labelWordBook: {
            float: 'left',
            color: '#666666',
        },
        wordListContent: {
            width: theme.spacing.unit * 81,
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        wordListContainer: {
            float: 'left',
            width: '100%',
            minHeight: theme.spacing.unit * 14,
            borderRadius: theme.spacing.unit * 0.5,
            border: '2px solid #bbbbbb',
            paddingTop: theme.spacing.unit,
            paddingLeft: theme.spacing.unit,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            [theme.breakpoints.down('md')]: {
                minHeight: theme.spacing.unit * 32,
            },
            '&& input': {
                paddingBottom: 80,
                [theme.breakpoints.down('md')]: {
                    paddingBottom: 220,
                },
            },
            '&& p': {
                marginTop: '-15px',
                marginBottom: '15px',
            },
        },
        button: {
            float: 'left',
            minWidth: theme.spacing.unit * 24.5,
            maxWidth: theme.spacing.unit * 31,
            height: theme.spacing.unit * 5.5,
            margin: `0 auto ${theme.spacing.unit * 3}px`,
            backgroundColor: `${theme.palette.secondary.main}`,
            borderRadius: theme.spacing.unit * 6,
        },
        switchLock: {
            float: 'right',
        },
        gridSwitch: {
            width: theme.spacing.unit * 81,
        },
        gridBtn: {
            float: 'left',
            width: theme.spacing.unit * 81,
            minHeight: theme.spacing.unit * 7.5,
            paddingTop: theme.spacing.unit,
            paddingLeft: theme.spacing.unit,
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        labelSwitch: {
            float: 'left',
            color: '#666666',
            marginTop: theme.spacing.unit * 1.5,
        },
        labelTemplate: {
            float: 'right',
            color: '#ff7300',
            cursor: 'pointer',
            marginRight: '33%',
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
            },
        },
        gridFormControl: {
            width: '100%',
        },
        options: {
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
        buttonSelected: {
            border: '1px solid #666666',
            backgroundColor: '#ffffff',
            color: '#666666',
            borderRadius: 6,
            height: '48px',
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ff7300',
                    backgroundColor: '#ffffff',
                },
            },
            '&:disabled': {
                border: '1px solid #666666',
                backgroundColor: '#ffffff',
                color: '#666666',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        buttonDateSelected: {
            borderRadius: 6,
            backgroundColor: '#ff7300',
            border: '1px solid #666666',
            color: 'white',
            height: '48px',
            marginRight: '8px',
            '&:disabled': {
                border: '1px solid #666666',
                backgroundColor: '#ffffff',
                color: '#666666',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        buttonTimeSelected: {
            border: '1px solid #ff7300',
            backgroundColor: '#ff7300',
            color: '#ffffff',
            borderRadius: 6,
            height: '48px',
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
            '&:disabled': {
                border: '1px solid #ff7300',
                backgroundColor: '#ff7300',
                color: '#ffffff',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        buttonTimeUnSelected: {
            border: '1px solid #ff7300',
            backgroundColor: '#ffffff',
            color: '#ff7300',
            borderRadius: 6,
            height: '48px',
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ff7300',
                    backgroundColor: '#ffffff',
                },
            },
            '&:disabled': {
                border: '1px solid #666666',
                backgroundColor: '#ffffff',
                color: '#666666',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        commonModal: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            overflow: 'scroll',
            outline: 'none',
        },
        header: {
            width: '100%',
            backgroundColor: '#607d8b',
        },
        headingModal: {
            margin: '0 auto',
            fontSize: theme.spacing.unit * 2,
            fontWeight: 'bold',
        },
        close: {
            fontSize: '28px',
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
        },
        openModalBtn: {
            textAlign: 'right',
            marginBottom: theme.spacing.unit * 2,
            paddingLeft: 4,
            paddingRight: 0,
            '&& span': {
                fontWeight: 500,
                color: '#ff7300',
                fontSize: '15px',
            },
        },
        clearWordlist: {
            textAlign: 'left',
            marginBottom: theme.spacing.unit * 2,
            paddingLeft: 0,
            paddingRight: 0,
            '&& span': {
                fontWeight: 500,
                color: '#666666',
                fontSize: '15px',
            },
        },
        chipContainer: {
            marginBottom: 0,
        },
        wordlistName: {
            backgroundColor: theme.palette.grey[200],
        },
        wordlistNameLine: {
            width: '75%',
            color: '#666666',
            fontSize: '15px',
        },
        wordlistSetting: {
            color: '#666666',
            fontSize: '15px',
        },
        wordlistAttention: {
            width: '130px',
            color: '#666666',
            fontSize: '15px',
        },
        wordlistNameError: {
            marginInlineStart: '4px',
            color: '#ff3d00',
            fontSize: '12px',
        },
    });

type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    skipEffect?: boolean;
    isShared: boolean;
}

const FileNameComponent: React.SFC<Props> = (props: Props) => {
    const { classes } = props;
    const newEndpointContainer = NewEndpointContainer.useContainer();

    const fileNameErrorMessage = () => {
        const splite = getSplitNewLineString(newEndpointContainer.fileNameErrorMessage);
        const components: JSX.Element[] = [];
        for (let text of splite) {
            components.push(<Typography className={classes.wordlistNameError}>{text}</Typography>);
        }
        return components;
    };

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom className={classes.heading}>
                        {locale.t(locale.keys.fileName.rule.index)}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom className={classes.example}>
                        {locale.t(locale.keys.fileName.example.index)}
                        {newEndpointContainer.getFileNameExample()}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.fileNameErrorGrid}>
                    {!newEndpointContainer.isDisabled(3) ? <></> : fileNameErrorMessage()}
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.ruleContainer}>
                        <ChipInput
                            InputProps={{ id: 'namerule-chipinput' }}
                            disableUnderline={true}
                            classes={{ chipContainer: classes.chipContainer }}
                            value={newEndpointContainer.itemList}
                            chipRenderer={({ value, handleDelete, className }, key) => {
                                if (value === locale.t(locale.keys.fileName.date.index)) {
                                    return <Chip key={key} className={className} onDelete={handleDelete} label={newEndpointContainer.handleInputDateButton()} tabIndex={-1} />;
                                }
                                const noFolder = Number(value.split(locale.t(locale.keys.fileName.folderLabel.index))[1]);
                                if (!isNaN(noFolder) && noFolder >= 5) {
                                    return (
                                        <Chip
                                            key={key}
                                            className={className}
                                            onClick={() => newEndpointContainer.handleClickChip(key, value)}
                                            color={newEndpointContainer.itemSelected === key ? 'secondary' : 'default'}
                                            onDelete={handleDelete}
                                            label={value}
                                            tabIndex={0}
                                        />
                                    );
                                }
                                return (
                                    <Chip
                                        key={key}
                                        className={className}
                                        color={newEndpointContainer.itemSelected === key ? 'secondary' : 'default'}
                                        onDelete={handleDelete}
                                        label={value}
                                        tabIndex={-1}
                                    />
                                );
                            }}
                            onAdd={(chip: string) => newEndpointContainer.handleAddItem(chip)}
                            onDelete={(chip: string, index: number) => newEndpointContainer.handleDeleteItem(chip, index)}
                            error={newEndpointContainer.fileNameLimitErrorMessege !== ''}
                            helperText={newEndpointContainer.fileNameLimitErrorMessege}
                            fullWidth
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.extensionContainer}>
                        <Typography variant="body2" className={classes.extension}>
                            {locale.t(locale.keys.fileName.extension.index)}
                        </Typography>
                    </div>
                </Grid>

                <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.gridBtn}>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.handleDisableFolder(1)}
                        onClick={() => {
                            newEndpointContainer.handleClickFolderButton(1);
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.folder1.index)}
                    </Button>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.handleDisableFolder(2)}
                        onClick={() => {
                            newEndpointContainer.handleClickFolderButton(2);
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.folder2.index)}
                    </Button>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.handleDisableFolder(3)}
                        onClick={() => {
                            newEndpointContainer.handleClickFolderButton(3);
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.folder3.index)}
                    </Button>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.handleDisableFolder(4)}
                        onClick={() => {
                            newEndpointContainer.handleClickFolderButton(4);
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.folder4.index)}
                    </Button>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.handleDisableFolder(5)}
                        onClick={() => {
                            newEndpointContainer.handleClickFolderButton(5);
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.folder5.index)}
                    </Button>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.anyCharaterFlag}
                        onClick={() => {
                            newEndpointContainer.handleClickAnyCharaterButton();
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.anyCharater.index)}
                    </Button>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.userFlag}
                        onClick={() => {
                            newEndpointContainer.handleClickUserButton();
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.user.index)}
                    </Button>
                    <Button
                        className={classes.buttonFrame}
                        variant="contained"
                        disabled={newEndpointContainer.dateFlag}
                        onClick={() => {
                            newEndpointContainer.handleClickDateButton();
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.date.index)}
                    </Button>
                    <Button
                        className={!newEndpointContainer.dateFlag ? classes.buttonSelected : newEndpointContainer.isYearSelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                        variant="contained"
                        disabled={newEndpointContainer.isButtonDisable('year')}
                        onClick={() => {
                            newEndpointContainer.handleClickYearButton();
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.year.index)}
                    </Button>
                    <Button
                        className={!newEndpointContainer.dateFlag ? classes.buttonSelected : newEndpointContainer.isMonthSelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                        variant="contained"
                        disabled={newEndpointContainer.isButtonDisable('month')}
                        onClick={() => {
                            newEndpointContainer.handleClickMonthButton();
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.month.index)}
                    </Button>
                    <Button
                        className={!newEndpointContainer.dateFlag ? classes.buttonSelected : newEndpointContainer.isDaySelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                        variant="contained"
                        disabled={newEndpointContainer.isButtonDisable('day')}
                        onClick={() => {
                            newEndpointContainer.handleClickDayButton();
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.day.index)}
                    </Button>
                    <Button
                        className={!newEndpointContainer.dateFlag ? classes.buttonSelected : newEndpointContainer.isHourSelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                        variant="contained"
                        disabled={newEndpointContainer.isButtonDisable('hour')}
                        onClick={() => {
                            newEndpointContainer.handleClickTimeButton();
                            newEndpointContainer.handleDeleteFileNameLimitErrorMessege();
                        }}
                    >
                        {locale.t(locale.keys.fileName.time.index)}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.formControlLabel}
                        control={<Checkbox checked={newEndpointContainer.canEmpty} onChange={newEndpointContainer.handleChangeCanEmpty} />}
                        label={props.isShared ? locale.t(locale.keys.fileName.checkboxLabel.shareIndex) : locale.t(locale.keys.fileName.checkboxLabel.index)}
                    />
                </Grid>

                <Grid item container direction="column" spacing={32}>
                    {/* セパレーター */}
                    <Grid item container direction="column" spacing={16} className={classes.gridFormControl}>
                        <Grid item>
                            <Typography variant="body2" gutterBottom className={classes.heading}>
                                {locale.t(locale.keys.fileName.separatorTitle)}
                            </Typography>
                        </Grid>
                        <Grid item container direction="row" spacing={16}>
                            <Grid item>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        className={classes.select}
                                        value={newEndpointContainer.separatorSelected}
                                        onChange={newEndpointContainer.handleChangeSeparatorSelected}
                                        input={<FilledInput />}
                                    >
                                        <MenuItem value="none">{locale.t(locale.keys.fileName.none.index)}</MenuItem>
                                        <MenuItem value={separate.DOT}>{locale.t(locale.keys.fileName.separatorDot.index)}</MenuItem>
                                        <MenuItem value={separate.DASH}>{locale.t(locale.keys.fileName.separatorHyphen.index)}</MenuItem>
                                        <MenuItem value={separate.UNDERSCORE}>{locale.t(locale.keys.fileName.separatorUnderscore.index)}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl className={classes.formControl} disabled={!newEndpointContainer.isDateSelected()}>
                                    <Select
                                        className={classes.select}
                                        value={newEndpointContainer.dateSeparatorSelected}
                                        onChange={newEndpointContainer.handleChangeDateSeparatorSelected}
                                        input={<FilledInput />}
                                    >
                                        <MenuItem value={dateSeparate.NONE}>{locale.t(locale.keys.fileName.dateSeparator.none)}</MenuItem>
                                        <MenuItem value={dateSeparate.DOT}>{locale.t(locale.keys.fileName.dateSeparator.dot)}</MenuItem>
                                        <MenuItem value={dateSeparate.HYPHEN}>{locale.t(locale.keys.fileName.dateSeparator.hyphen)}</MenuItem>
                                        <MenuItem value={dateSeparate.UNDERSCORE}>{locale.t(locale.keys.fileName.dateSeparator.underscore)}</MenuItem>
                                        <MenuItem value={dateSeparate.C}>{locale.t(locale.keys.fileName.dateSeparator.c)}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* 複数枚原稿連番付け */}
                    <Grid item container direction="column" spacing={16} className={classes.gridFormControl}>
                        <Grid item>
                            <Typography variant="body2" gutterBottom className={classes.heading}>
                                {locale.t(locale.keys.fileName.severalPagesTitle)}
                            </Typography>
                        </Grid>

                        <Grid item container direction="row" spacing={16}>
                            <Grid item>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        data-testid={'sequenceTypeSelected'}
                                        className={classes.select}
                                        value={newEndpointContainer.sequenceTypeSelected}
                                        onChange={newEndpointContainer.handleChangeSequenceTypeSelected}
                                        input={<FilledInput />}
                                    >
                                        <MenuItem className={classes.options} value={sequenceType.COUNT}>
                                            {locale.t(locale.keys.fileName.severalPagesCount.index)}
                                        </MenuItem>
                                        <MenuItem className={classes.options} value={sequenceType.PUT}>
                                            {locale.t(locale.keys.fileName.severalPagesPut.index)}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className={classes.gridSwitch}>
                    <div className={classes.switchLock}>
                        <Typography variant="body2" gutterBottom className={classes.labelSwitch}>
                            {locale.t(locale.keys.fileName.switchBlock.index)}
                        </Typography>
                        <Switch onChange={() => newEndpointContainer.handleChangeSwitch()} inputProps={{ 'aria-label': 'secondary checkbox' }} checked={newEndpointContainer.isLock} />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className={classes.extensionContainer}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item container alignItems="center" direction="row" justify="flex-start" className={classes.wordlistNameLine}>
                                <Typography className={classes.labelWordBook}>{locale.t(locale.keys.fileName.wordbook.prefix)}</Typography>
                                <TextField
                                    value={newEndpointContainer.wordListName01}
                                    className={classes.wordlistName}
                                    inputProps={{ maxlength: 33, 'data-testid': 'wordlistname1-input' }}
                                    error={newEndpointContainer.errorsName.wordlistname !== ''}
                                    onChange={(evt) => {
                                        newEndpointContainer.handleChangeWordListName01(evt.target.value);
                                    }}
                                />
                                {newEndpointContainer.errorsName.wordlistname ? <Typography className={classes.wordlistNameError}>{newEndpointContainer.errorsName.wordlistname}</Typography> : <></>}
                            </Grid>
                            <Grid item alignItems="center" justify="flex-end" className={classes.wordlistAttention}>
                                <Typography style={{ textAlign: 'right', color: '#666666' }}>{locale.t(locale.keys.fileName.workBook.index)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container direction="column" className={classes.wordListContent}>
                        <Paper className={classes.wordListContainer}>
                            <ChipInput
                                disableUnderline={true}
                                classes={{ chipContainer: classes.chipContainer }}
                                value={newEndpointContainer.wordList}
                                onAdd={(chip: string) => newEndpointContainer.handleAddWord(chip)}
                                onDelete={(chip: string) => newEndpointContainer.handleDeleteWord(chip)}
                                fullWidth
                                error={newEndpointContainer.errors.wordlist !== ''}
                                helperText={newEndpointContainer.errors.wordlist}
                                chipRenderer={({ value, handleDelete, className }, key) => {
                                    return <Chip key={key} className={className} onDelete={handleDelete} label={value} tabIndex={-1} />;
                                }}
                            />
                        </Paper>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Button className={classes.clearWordlist} onClick={newEndpointContainer.clearWordList}>
                                {locale.t(locale.keys.fileName.clearWordList)}
                            </Button>
                            <Button className={classes.openModalBtn} onClick={newEndpointContainer.handleOpen}>
                                {locale.t(locale.keys.fileName.wordbook.title)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container className={classes.extensionContainer}>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item container alignItems="center" direction="row" justify="flex-start" className={classes.wordlistNameLine}>
                                <Typography className={classes.labelWordBook}>{locale.t(locale.keys.fileName.wordbook.prefix)}</Typography>
                                <TextField
                                    value={newEndpointContainer.wordListName02}
                                    className={classes.wordlistName}
                                    inputProps={{ maxlength: 33 }}
                                    error={newEndpointContainer.errorsName.wordlistname2 !== ''}
                                    onChange={(evt) => {
                                        newEndpointContainer.handleChangeWordListName02(evt.target.value);
                                    }}
                                />
                                {newEndpointContainer.errorsName.wordlistname2 ? <Typography className={classes.wordlistNameError}>{newEndpointContainer.errorsName.wordlistname2}</Typography> : <></>}
                            </Grid>
                            <Grid item alignItems="center" justify="flex-end" className={classes.wordlistAttention}>
                                <Typography style={{ textAlign: 'right', color: '#666666' }}>{locale.t(locale.keys.fileName.workBook.index)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container direction="column" className={classes.wordListContent}>
                        <Paper className={classes.wordListContainer}>
                            <ChipInput
                                disableUnderline={true}
                                classes={{ chipContainer: classes.chipContainer }}
                                value={newEndpointContainer.wordList2}
                                onAdd={(chip: string) => newEndpointContainer.handleAddWord2(chip)}
                                onDelete={(chip: string) => newEndpointContainer.handleDeleteWord2(chip)}
                                fullWidth
                                error={newEndpointContainer.errors.wordlist2 !== ''}
                                helperText={newEndpointContainer.errors.wordlist2}
                                chipRenderer={({ value, handleDelete, className }, key) => {
                                    return <Chip key={key} className={className} onDelete={handleDelete} label={value} tabIndex={-1} />;
                                }}
                            />
                        </Paper>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Button className={classes.clearWordlist} onClick={newEndpointContainer.clearWordList2}>
                                {locale.t(locale.keys.fileName.clearWordList)}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={newEndpointContainer.open} onClose={newEndpointContainer.handleClose}>
                    <div className={classes.commonModal}>
                        <AppBar position="static" className={classes.header}>
                            <Toolbar variant="dense">
                                <Typography color="inherit" className={classes.headingModal}>
                                    {locale.t(locale.keys.fileName.wordbook.title)}
                                </Typography>
                                <IconButton color="inherit" className={classes.close} onClick={newEndpointContainer.handleClose}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Wordbook closeModal={newEndpointContainer.handleClose} wordbooks={newEndpointContainer.replaceWordbook} />
                    </div>
                </Modal>
            </Grid>
        </>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(FileNameComponent));
