import * as schema from '@/bundles/schema/typescript/schema';
import { StateContainer } from '@/common/components/AppContainerBase';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as locale from '@/common/utils/locale/locale';
import * as errorLocale from '@/common/utils/locale/error-locale';
import axios, { AxiosError } from 'axios';

/**
 * @description axiosを使用してfetchした場合のエラーハンドリング
 * 将来的にaxiosのinterceptorに処理をまとめたい（packages/webapp/src/common/utils/axiosFactory.ts）
 */
const axiosResponseHandler = (container: StateContainer, axiosError: AxiosError<schema.V1ErrorsErrorResponse>) => {
    // 意図しないレスポンスが返却されたら不明なエラーを表示
    if (axiosError.response === undefined) {
        container.updateMessage({
            isOpen: true,
            message: locale.t(locale.keys.error.unknown),
            variant: Variants.error,
        });

        return null;
    }

    // middlewareから返される認証系エラーの場合は強制ログアウト
    switch (axiosError.response.data.error.errorId) {
        case errorLocale.keys.E02005:
        case errorLocale.keys.E02008:
        case errorLocale.keys.E02009:
        case errorLocale.keys.E02012:
            forceSignout(container);
            break;
    }

    // HTTPステータスエラーごとの処理
    switch (axiosError.response.status) {
        // Gateway Timeout
        case 504:
            const errorObject = errorLocale.translate(errorLocale.keys.E99001);
            container.updateMessage({
                isOpen: true,
                message: errorObject.message,
                variant: Variants.error,
                resultCode: errorObject.errorId,
            });
            return null;
    }

    // 意図しないレスポンスが返却されたら不明なエラーを表示
    if (axiosError.response.data === undefined) {
        container.updateMessage({
            isOpen: true,
            message: locale.t(locale.keys.error.unknown),
            variant: Variants.error,
        });
        return null;
    }

    // APIから整形されたエラーを受け取った場合はトーストで表示
    container.updateMessage({
        isOpen: true,
        message: axiosError.response.data.error.message,
        variant: Variants.error,
        resultCode: axiosError.response.data.error.errorId,
    });

    return axiosError.response.data.error;
};

/**
 * @param container
 * @param e Errorオブジェクト（それ以外を引数に指定した場合は不明なエラーを表示）
 * @description
 * APIからのエラーをハンドリングする。
 * 次のエラー後の処理を共通化する。①認証系エラーの際は強制ログアウト、②エラーメッセージをトーストで表示。
 */
export const handleApiError = (container: StateContainer, e: any): schema.Error | null => {
    try {
        if (axios.isAxiosError(e)) {
            const axiosError = e as AxiosError<schema.V1ErrorsErrorResponse>;
            const error = axiosResponseHandler(container, axiosError);
            return error;
        }

        container.updateMessage({
            isOpen: true,
            message: locale.t(locale.keys.error.unknown),
            variant: Variants.error,
        });
        return null;
    } catch (_) {
        container.updateMessage({
            isOpen: true,
            message: locale.t(locale.keys.error.unknown),
            variant: Variants.error,
        });
        return null;
    }
};

export interface HttpStatusErrorResponse {
    statusCode: number;
    result?: string;
}

export const forceSignout = (container: StateContainer) => {
    container.onSignout(container, container.values);
};

export const isHttpStatusErrorResponse = (e: any): e is HttpStatusErrorResponse => {
    return e.statusCode !== undefined;
};

export const isV1ErrorsErrorResponse = (errJson: any): errJson is schema.V1ErrorsErrorResponse => {
    return errJson.error_summary !== undefined && errJson.error !== undefined;
};
