import queryString from 'query-string';
import * as schema from '@/bundles/schema/typescript/schema';
import * as cookies from '@/common/utils/browser/cookies';
import * as models from '@/user/models/cloud-connection/cloud-connection';
import shareFormat from '@/common/constants/shareFormat';
import { getAuthLink } from '@/user/models/x-storages/order/management';
import { isHttpStatusErrorResponse, isV1ErrorsErrorResponse } from '@/common/utils/errorHandler';

// 単体テストでmockするために切り出し
export const getQuery = () => {
    const query = queryString.parse(document.location.search);
    const code = query.code as string | null | undefined;
    const id = query.id;

    return {
        code,
        id,
    };
};

// 認可を行うためには、サービスの種類、APIへリクエストするためのトークン、認可依頼からの認可かどうかを判別するためのorderIdが必要
const AUTH_SERVICE_KEY = 'auth_cloud_service';
const AUTH_TOKEN_KEY = 'auth_cloud_service_token';
const AUTH_ORDERID_KEY = 'auth_cloud_service_orderid';
export const getCookie = () => {
    const connectionService = cookies.get(AUTH_SERVICE_KEY) as schema.V1ObjectsServiceEnum | null;
    const token = cookies.get<string>(AUTH_TOKEN_KEY);
    const orderId = cookies.get<string>(AUTH_ORDERID_KEY);

    return {
        connectionService,
        token,
        orderId,
    };
};

export const setAuthReuiredCookie = (service: schema.V1ObjectsServiceEnum, token: string, orderId: string) => {
    // 長期間保存しておくのはリスクなので、Cookieの期限は30分
    cookies.set(AUTH_SERVICE_KEY, service, { expires: 1 / 48 });
    cookies.set(AUTH_TOKEN_KEY, token, { expires: 1 / 48 });
    cookies.set(AUTH_ORDERID_KEY, orderId, { expires: 1 / 48 });
};

// 認可後のcodeパラメータを画面間で受け渡しをしたい
const AUTH_STORAGE_CODE = 'auth_storage_code';
export const getAuthCookie = () => {
    return cookies.get<string>(AUTH_STORAGE_CODE);
};

export const setAuthCookie = (code: string) => {
    // Cookieの期限は1分
    cookies.set(AUTH_STORAGE_CODE, code, { expires: 1 / 1440 });
};

export const generateUserCloud = async (service: schema.V1ObjectsServiceEnum, code: string, orderId: string, token: string) => {
    let authorCode = code;
    if (service === schema.V1ObjectsServiceEnum.Docab || service === schema.V1ObjectsServiceEnum.Docard) {
        authorCode = decodeURIComponent(code);
    }
    const reqObj: schema.V1XStoragesCreateRequest = {
        service,
        authorCode,
        // 個人用のクラウドストレージ、共有用ではない
        share: shareFormat.personal,
        orderId: orderId,
    };
    await models.createUserCloud(reqObj, token);
};

export const createAuthLink = async (orderId: string, code: string) => {
    const link = await getAuthLink(orderId, code);
    setAuthReuiredCookie(link.service, code, orderId);
    return link;
};

export const removeTempCookie = () => {
    cookies.remove(AUTH_SERVICE_KEY);
    cookies.remove(AUTH_TOKEN_KEY);
    cookies.remove(AUTH_STORAGE_CODE);
    cookies.remove(AUTH_ORDERID_KEY);
    return;
};

export enum Status {
    SUCCESS = 'success',
    ERROR = 'error',
    LOADING = 'loading',
    UNKNOWN = 'unknown',
    CLOUD_CONNECTION = 'cloudConnection',
}
export type StatusObj = {
    status: Status;
    statusCode: number | null;
    errorCode: string | null;
};

export const handleClosePopUp = async (link: string, setStatus: React.Dispatch<React.SetStateAction<StatusObj>>) => {
    const popup = window.open(link, 'CloudAuth', 'height=682,width=744');
    const intervalObj = setInterval(async () => {
        if (popup && popup.closed) {
            clearInterval(intervalObj);
            let status: StatusObj = {
                status: Status.LOADING as Status,
                statusCode: null,
                errorCode: null,
            };
            setStatus({ ...status });
            status = await closeAction();
            setStatus({ ...status });
        }
    }, 1000);
};

// 認可ポップアップを閉じたときに生じるエラーメッセージ
// エラーハンドリングの為の条件分岐に使用するので定義しておく
const errorKey = 'NotFoundCookie';
export const closeAction = async (): Promise<StatusObj> => {
    try {
        // 認可に必要な情報が無ければエラー
        // このエラーが発生するのはポップアップを閉じたときのみ
        const code = getAuthCookie();
        const cookie = getCookie();
        if (!code || !cookie.connectionService || !cookie.token || !cookie.orderId) throw Error(errorKey);

        await generateUserCloud(cookie.connectionService, code, cookie.orderId, cookie.token);

        removeTempCookie();
        return {
            status: Status.SUCCESS as Status,
            statusCode: null,
            errorCode: null,
        };
    } catch (e) {
        if (e instanceof Error) {
            return errorHandler(e);
        } else {
            // eはErrorのインスタンスのはずなのでここに到達する場合は原因不明
            return {
                status: Status.ERROR as Status,
                statusCode: 500,
                errorCode: null,
            };
        }
    }
};

export const errorHandler = (e: Error) => {
    // ポップアップを閉じたときに発生するエラー
    if (e.message === errorKey) {
        return {
            status: Status.CLOUD_CONNECTION as Status,
            statusCode: null,
            errorCode: null,
        };
    }
    // 「eのインスタンスがErrorではない」かつ「e.messageがJSONにparseできない文字列」の場合、JSON.parseで実行時エラーが発生する
    try {
        const errJson = JSON.parse(e.message);
        // 無効なクラウドストレージの場合または、許可されていないドメインの場合はschema.V1ErrorsErrorResponse型でAPIから受け取る
        if (isV1ErrorsErrorResponse(errJson)) {
            return {
                status: Status.ERROR as Status,
                statusCode: null,
                errorCode: errJson.error.errorId || null,
            };
        }
        // HttpStatusErrorResponse型でエラーが発生することは想定できないので不明なエラー
        if (isHttpStatusErrorResponse(e)) {
            return {
                status: Status.ERROR as Status,
                statusCode: e.statusCode,
                errorCode: null,
            };
        }
        // どの条件にも当てはまらない場合は想定できないのでエラー
        throw new Error();
    } catch {
        return {
            status: Status.ERROR as Status,
            statusCode: 500,
            errorCode: null,
        };
    }
};
