import * as React from 'react';
import { Theme, createStyles, withStyles, WithStyles, Modal } from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import constants from '@/common/constants';
import { default as AdminMenu } from '@/admin/components/AdminMenu';
import { default as AdminHeaderMenu } from '@/admin/components/AdminHeaderMenu';
import { default as UserMenu } from '@/user/components/UserMenu';
import { default as UserHeaderMenu } from '@/user/components/UserHeaderMenu';

const isAdmin = window.location.host.includes('admin');
const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('md')]: {
                borderRight: '1px solid',
                borderColor: theme.palette.grey[400],
                height: 'auto',
                // `calc(1vh - ${constants.ui.headerHeight}px)`,
                width: constants.ui.drawerWidth + 16,
                flexShrink: 0,
            },
            height: 'auto',
            backgroundColor: isAdmin ? '#e3eaed' : '#ffffff',
        },
        paper: {
            borderRadius: 5,
            position: 'fixed',
            top: 60,
            right: theme.spacing.unit,
            width: '80%',
            minWidth: 250,
            maxWidth: 250,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: 'none',
            transition: '0.2s',
        },
    });

interface Props extends WithStyles<typeof styles> {
    isAdminApp: boolean;
    isAdminUser: boolean;
    theme: Theme;
    commonMenuOpen: boolean;
    commonMenuCloseFunc: () => void;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes, theme } = props;
    const matchesBreakPoint = useMediaQuery(theme.breakpoints.up('md'));
    const matchesBreakPointUser = useMediaQuery(theme.breakpoints.up('lg'));
    const drawer = (
        <>
            {!props.isAdminApp ? (
                <UserMenu isAdminUser={props.isAdminUser} commonMenuOpen={props.commonMenuOpen} commonMenuCloseFunc={props.commonMenuCloseFunc} />
            ) : (
                <AdminMenu isAdminUser={props.isAdminUser} commonMenuOpen={props.commonMenuOpen} commonMenuCloseFunc={props.commonMenuCloseFunc} />
            )}
        </>
    );

    return (
        <>
            {matchesBreakPoint ? (
                <>
                    {!matchesBreakPointUser && !props.isAdminApp ? (
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={props.commonMenuOpen}
                            onClose={props.commonMenuCloseFunc}
                            BackdropProps={{
                                invisible: true,
                            }}
                        >
                            <div className={classes.paper}>{drawer}</div>
                        </Modal>
                    ) : (
                        <>
                            <aside className={classes.drawer}>{drawer}</aside>
                            {props.commonMenuOpen && (
                                <Modal
                                    aria-labelledby="simple-modal-title"
                                    aria-describedby="simple-modal-description"
                                    open={props.commonMenuOpen}
                                    onClose={props.commonMenuCloseFunc}
                                    BackdropProps={{
                                        invisible: true,
                                    }}
                                >
                                    <div className={classes.paper}>
                                        {props.isAdminApp ? <AdminHeaderMenu commonMenuCloseFunc={props.commonMenuCloseFunc} /> : <UserHeaderMenu commonMenuCloseFunc={props.commonMenuCloseFunc} />}
                                    </div>
                                </Modal>
                            )}
                        </>
                    )}
                </>
            ) : (
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={props.commonMenuOpen}
                    onClose={props.commonMenuCloseFunc}
                    BackdropProps={{
                        invisible: true,
                    }}
                >
                    <div className={classes.paper}>{drawer}</div>
                </Modal>
            )}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
