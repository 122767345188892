import * as React from 'react';

import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { default as UI } from '@/common/constants/ui';
import * as locale from '@/common/utils/locale/locale';
import { context } from '@/user/components/mfp/Context';
import { IMFP, defaultValue, MFP } from '@/user/models/mfps/shareDevice';
import AddButton from '@/user/components/mfp/AddButton';
import { Printer } from '@/user/components/mfp/printerList';
import RegisteredList from '@/user/components/mfp/RegisteredList';
import ModalOuter from '@/user/components/common/ModalOuter';
import { updateMfpUser, updatePin } from '@/user/models/mfps/users/mfp-users';
import * as schema from '@/bundles/schema/typescript/schema';
import * as mfpsModel from '@/user/models/workspaces/mfps/mfps';
import { Variants } from '@/common/components/messages/CommonMessage';
import * as queryString from 'query-string';
import * as userMfpsModel from '@/user/models/user/mfps/user-mfps';
import AddButtonPC from '@/user/components/mfp/AddButtonPC';
import * as auth from '@/user/models/auth';
import { StateContainer } from '@/common/components/AppContainerBase';
import { isLoaded, isLoading, isError, isSaving } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import { PAGINATION_LIMIT_MFP } from '@/common/constants/pagination';

const Provider = context.Provider;

const styles = (theme: Theme) =>
    createStyles({
        container: {
            minHeight: '100%',
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                paddingTop: theme.spacing.unit * -1.5,
            },
        },
        btnAddPC: {
            zIndex: 1,
            position: 'absolute',
            marginTop: -theme.spacing.unit * 2,
            right: theme.spacing.unit * 2,
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
            marginBottom: theme.spacing.unit * 3,
        },
        btnAddSP: {
            zIndex: 1,
            position: 'fixed',
            bottom: theme.spacing.unit * 3,
            right: theme.spacing.unit * 3,
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        pinContainer: {
            textAlign: 'left',
            backgroundColor: theme.palette.grey[200],
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
            width: 120,
            '&& input': {
                textAlign: 'center',
            },
            '&& input::-webkit-inner-spin-button': {
                appearance: 'none',
                margin: 0,
            },
            '&& input::-webkit-outer-spin-button': {
                appearance: 'none',
                margin: 0,
            },
            '&& label': {
                fontSize: '0.875rem',
                transform: 'none',
                top: theme.spacing.unit * 1,
                left: theme.spacing.unit * 1,
            },
            '&& div': {
                marginTop: theme.spacing.unit * 4,
            },
            // marginTop: theme.spacing.unit * 3,
        },
        btnSavePin: {
            textAlign: 'left',
            marginTop: theme.spacing.unit * 1,
        },
        btnSave: {
            backgroundColor: '#F06E00',
            color: 'white',
        },
    });

interface Props extends WithStyles<typeof styles> {
    onTitle: () => void;
    appContainer?: StateContainer;
}

export class Component extends React.Component<Props, IMFP> {
    constructor(props: Props) {
        super(props);
        this.state = defaultValue;
    }

    getStateObject = (uiString: string) => {
        if (uiString === UI.state.Loading) {
            return UI.state.Loading;
        }
        if (uiString === UI.state.Saving) {
            return UI.state.Saving;
        }
        if (uiString === UI.state.Loaded) {
            return UI.state.Loaded;
        }
        return UI.state.Error;
    };

    componentWillMount = async () => {
        const got = queryString.parse(window.location.search);
        if (typeof this.props.appContainer!.setLoadingState !== 'undefined' && isLoading(this.getStateObject(this.state.uiState))) {
            this.props.appContainer!.setLoadingState(true);
        }
        let token;
        if (got && got.token && typeof got.token === 'string') {
            token = got.token;
        }
        if (!token) {
            this.closeMFPListModal();
        }
        if (token) {
            this.getMfps(token);
        }
        // get all mfpList by sub
        try {
            // user_sub
            const user = auth.identifyUser();
            if (!user || !user.sub) {
                this.setState({ uiState: UI.state.Error });
                this.props.appContainer!.setLoadingState(false);
                return;
            }
            this.setState({
                User: {
                    sub: user.sub,
                    email: user.email,
                    mfpNumber: user.mfpNumber,
                },
            });
            const dataListRes = await userMfpsModel.getMfpList(user.sub, this.props.appContainer!.values.authorizationCode, 0, PAGINATION_LIMIT_MFP);
            this.setState({ defaultPinValue: dataListRes.pin || '' });
            this.setState({ isLocked: dataListRes.isLock || false });
            const dataList = [];
            for (const index in dataListRes.mfpUsers) {
                const item = dataListRes.mfpUsers[index];
                const data: MFP = {
                    id: item.id,
                    name: item.deviceName ? item.deviceName : '',
                    model: item.mfpName,
                    number: item.mfpNumber,
                    groups: item.groups,
                };
                dataList.push(data);
            }
            this.setState({ mfpList: dataList });
            this.setState({ isLocked: dataListRes.isLock || false });
            this.setState({ uiState: UI.state.Loaded });
            if (typeof this.props.appContainer!.setLoadingState !== 'undefined' && (isLoaded(this.getStateObject(this.state.uiState)) || isError(this.getStateObject(this.state.uiState)))) {
                this.props.appContainer!.setLoadingState(false);
            }
            if (isError(this.getStateObject(this.state.uiState))) {
                this.props.appContainer!.setOnBackError(true);
                this.props.appContainer!.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.error),
                    variant: Variants.error,
                });
            }
        } catch (e) {
            this.setState({ uiState: UI.state.Error });
            this.props.appContainer!.setLoadingState(false);
            errorHandler.handleApiError(this.props.appContainer!, e);
        }
    };

    refreshMfpList = async () => {
        const dataListRes = await userMfpsModel.getMfpList(this.state.User.sub, this.props.appContainer!.values.authorizationCode, 0, PAGINATION_LIMIT_MFP);
        const dataList = [];
        for (const index in dataListRes.mfpUsers) {
            const item = dataListRes.mfpUsers[index];
            const data: MFP = {
                id: item.id,
                name: item.deviceName ? item.deviceName : '',
                model: item.mfpName,
                number: item.mfpNumber,
                groups: item.groups,
            };
            dataList.push(data);
        }
        this.setState({ mfpList: dataList });
    };

    handleBeforeUnload = (event: any) => {
        event.returnValue = 'You quit without saving.';
        event.preventDefault();
        return 'unloading';
    };

    componentWillUnmount() {
        if (typeof this.props.appContainer!.setOnBackError !== 'undefined') {
            this.props.appContainer!.setOnBackError(false);
        }
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidUpdate() {
        if (isError(this.getStateObject(this.state.uiState))) {
            this.props.appContainer!.setOnBackError(true);
        }
        if (!this.state.isEdit) {
            window.removeEventListener('beforeunload', this.handleBeforeUnload);
            return;
        }
        if (this.state.isEdit) {
            window.addEventListener('beforeunload', this.handleBeforeUnload);
        }
    }

    componentDidMount() {
        document.title = locale.t(locale.keys.pageTitle.mfp.index);
        this.props.onTitle();
        if (isError(this.getStateObject(this.state.uiState))) {
            this.props.appContainer!.setOnBackError(true);
            this.props.appContainer!.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.error),
                variant: Variants.error,
            });
        }
    }

    openMFPListModal = () => {
        this.setState({ mfpListModal: true });
    };

    closeMFPListModal = () => {
        this.setState({ mfpListModal: false });
        this.setState({ activePrinterOpen: false });
    };

    handleActivePrinter = (id: string) => {
        const active = this.state.printerList.find((printer) => printer.id === id);
        if (active) {
            this.setState({ activePrinter: active });
        }
        this.setState({ activePrinterOpen: !this.state.activePrinterOpen });
    };

    toggleActivePrinter = () => {
        this.setState({ activePrinterOpen: !this.state.activePrinterOpen });
    };

    changePin = (event: React.ChangeEvent<HTMLInputElement>) => {
        let pin = '';
        if (event.target.value.match(/[0-9]+/)) {
            pin = event.target.value.match(/[0-9]+/)![0];
        } else {
            pin = '';
        }
        this.setState({ defaultPinValue: pin });
        this.setState({ isEdit: true });
        if (String(pin).length === 6) {
            this.setState({ pinFormDisable: false });
        } else {
            this.setState({ pinFormDisable: true });
        }
    };

    handleSaveClick = async () => {
        const pinValue = this.state.defaultPinValue;
        const { email } = auth.identifyUser();
        // update saving state
        this.props.appContainer!.setLoadingState(true);
        this.setState({ uiState: UI.state.Saving });
        this.setState({ isEdit: false });
        try {
            const data = await updatePin(email, '', pinValue, this.props.appContainer!.values.authorizationCode);
            if (!data) {
                this.setState({ uiState: UI.state.Error });
                this.props.appContainer!.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.updateFailed),
                    variant: Variants.error,
                });
                return;
            }
            // update saving state
            this.props.appContainer!.setLoadingState(false);
            this.setState({ uiState: UI.state.Loaded });
            this.setState({ pinFormDisable: true });
            this.props.appContainer!.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
        } catch (e) {
            this.setState({ uiState: UI.state.Error });
            this.props.appContainer!.setLoadingState(false);
            errorHandler.handleApiError(this.props.appContainer!, e);
        }
    };

    handleUnlockDevices = async () => {
        try {
            this.props.appContainer!.setLoadingState(true);
            this.setState({ uiState: UI.state.Saving });
            // user_sub
            const sub = this.state.User.sub;
            const unLockResponse = await userMfpsModel.update(sub, this.props.appContainer!.values.authorizationCode);
            if (!unLockResponse) {
                this.props.appContainer!.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.error.unknown),
                    variant: Variants.error,
                });
                return;
            }
            if (unLockResponse.status === schema.FileStatusEnum.Completed) {
                this.setState({ isLocked: false });
                this.props.appContainer!.setLoadingState(false);
                this.setState({ uiState: UI.state.Loaded });
            }
        } catch (e) {
            this.setState({ uiState: UI.state.Error });
            this.props.appContainer!.setLoadingState(false);
            errorHandler.handleApiError(this.props.appContainer!, e);
        }
    };

    registerMFP = async (printer: any) => {
        const mfp = printer;
        let currentMFP = {
            ...mfp,
        };

        // Call api to update
        const { email } = auth.identifyUser();
        this.props.appContainer!.setLoadingState(true);
        try {
            for (const i in this.state.mfpList) {
                // 複合機はグループでの登録とユーザーでの登録の両方が共存可能
                const groups = this.state.mfpList[i].groups;
                if (groups && groups.length !== 0) {
                    continue;
                }
                if (this.state.mfpList[i].number === currentMFP.number) {
                    this.props.appContainer!.setLoadingState(false);
                    this.props.appContainer!.updateMessage({
                        autoHideDuration: 3000,
                        isOpen: true,
                        message: locale.t(locale.keys.modalLabel.mfp.registerFailed),
                        variant: Variants.error,
                    });
                    return;
                }
            }
            const newMfp = await updateMfpUser(email, currentMFP.number, '', this.props.appContainer!.values.authorizationCode);
            currentMFP = {
                ...currentMFP,
                id: newMfp.userInfo.id,
            };
            const dataList = this.state.mfpList;
            dataList.push(currentMFP);
            this.setState({ mfpList: dataList });
            await this.refreshMfpList();
            this.props.appContainer!.setLoadingState(false);
            this.props.appContainer!.updateMessage({
                autoHideDuration: 3000,
                isOpen: true,
                message: locale.t(locale.keys.action.updated),
                variant: Variants.success,
            });
        } catch (e) {
            errorHandler.handleApiError(this.props.appContainer!, e);
        }
    };

    isCollapse = (collapseNumber: number | null, index: number) => (collapseNumber === index ? true : false);

    handleCollapseNumber = (collapseNumber: number | null, index: number) => {
        const cln = collapseNumber === index ? null : index;
        this.setState({ collapseNumber: cln });
    };

    confirmRemove = (isYes: boolean, value?: string) => {
        if (!isYes) {
            return;
        }
        if (typeof value === 'string') {
            this.removeMFP(value);
        }
    };

    removeMFP = async (i: string) => {
        try {
            this.props.appContainer!.setLoadingState(true);
            const index = Number(i);
            const cln = this.state.collapseNumber;
            const mfpListData = this.state.mfpList;
            const dataRes = await userMfpsModel.deleteMfp(this.state.mfpList[index].id, this.props.appContainer!.values.authorizationCode);

            if (dataRes) {
                this.props.appContainer!.updateMessage({
                    autoHideDuration: 3000,
                    isOpen: true,
                    message: locale.t(locale.keys.action.deleted),
                    variant: Variants.success,
                });
            }
            if (dataRes.status === schema.FileStatusEnum.Completed) {
                this.handleCollapseNumber(cln, index);
                mfpListData.splice(index, 1);
                this.setState({ mfpList: mfpListData });
            }
            await this.refreshMfpList();
            this.props.appContainer!.setLoadingState(false);
        } catch (e) {
            this.setState({ uiState: UI.state.Error });
            this.props.appContainer!.setLoadingState(false);
            errorHandler.handleApiError(this.props.appContainer!, e);
        }
    };

    cancelCourse = (index: number) => {
        const resetTarget: HTMLInputElement = document.getElementById(`js-reset${index}`) as HTMLInputElement;
        resetTarget.value = '';
    };

    getMfps = async (token?: string) => {
        try {
            this.setState({ mfpUI: UI.state.Loading });
            let mfpDatas;
            if (token) {
                const { mfpNumber } = auth.identifyUser(token);
                mfpDatas = await mfpsModel.getMfps(this.props.appContainer!.values.signinWorkspaceObject.id || '', this.props.appContainer!.values.authorizationCode, mfpNumber);
            }

            if (!token) {
                mfpDatas = await mfpsModel.getMfps(this.props.appContainer!.values.signinWorkspaceObject.id || '', this.props.appContainer!.values.authorizationCode);
            }

            const printers: Printer[] = [];
            if (mfpDatas) {
                const dataArr: schema.V1ObjectsWorkspacemfp[] = mfpDatas['mfps'];
                for (const i in dataArr) {
                    const mfp = dataArr[i];
                    if (mfp.status === schema.V1ObjectsWorkspacemfpStatus.Active) {
                        printers.push({
                            id: mfp.id,
                            number: mfp.mfpNumber,
                            name: mfp.deviceName,
                            model: mfp.mfpName,
                        });
                    }
                }
            }
            if (printers && 0 < printers.length) {
                this.setState({ activePrinter: printers[0] });
                this.setState({ printerList: printers });
            }
            this.setState({ mfpUI: UI.state.Loaded });
        } catch (e) {
            this.setState({ uiState: UI.state.Error });
            this.props.appContainer!.setLoadingState(false);
            errorHandler.handleApiError(this.props.appContainer!, e);
        }
    };

    handleChangeExpanded = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        this.setState({
            expanded: isExpanded ? panel : false,
        });
    };

    handleChange = (event: any) => {
        const active = this.state.printerList.find((printer) => printer.id === event.target.value);
        if (active) {
            this.setState({ activePrinter: active });
        }
    };

    render() {
        const { classes } = this.props;

        const value = {
            ...this.state,
            openMFPListModal: this.openMFPListModal,
            closeMFPListModal: this.closeMFPListModal,
            handleActivePrinter: this.handleActivePrinter,
            toggleActivePrinter: this.toggleActivePrinter,
            changePin: this.changePin,
            registerMFP: this.registerMFP,
            handleCollapseNumber: this.handleCollapseNumber,
            isCollapse: this.isCollapse,
            removeMFP: this.removeMFP,
            getMfps: this.getMfps,
            confirmRemove: this.confirmRemove,
            handleSaveClick: this.handleSaveClick,
            handleUnlockDevices: this.handleUnlockDevices,
            getStateObject: this.getStateObject,
            handleChangeExpanded: this.handleChangeExpanded,
            handleChange: this.handleChange,
        };

        return (
            <Provider value={value as any}>
                {isLoading(this.getStateObject(this.state.uiState)) && <div data-testid={UI.state.Loading} />}

                {isSaving(this.getStateObject(this.state.uiState)) && <div data-testid={UI.state.Saving} />}

                {isError(this.getStateObject(this.state.uiState)) && <div data-testid={UI.state.Error} />}

                {isLoaded(this.getStateObject(this.state.uiState)) && (
                    <div>
                        <div className={classes.container}>
                            <div className={classes.btnAddPC}>
                                <AddButtonPC />
                            </div>
                            <div className={classes.btnAddSP}>
                                <AddButton />
                            </div>
                            <RegisteredList />
                        </div>
                        <ModalOuter title={locale.t(locale.keys.pageTitle.mfp.index)} modalOpen={this.state.mfpListModal} modalCloseFunc={this.closeMFPListModal} />
                    </div>
                )}
            </Provider>
        );
    }
}

export default withStyles(styles)(Component);
