import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import '@/common/css/app.css';
import { default as commonConsts } from '@/common/constants';
import routes from '@/user/constants/routes';
import components from '@/user/components';
import LogoHeader from '@/common/components/headers/LogoHeader';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import { default as CovasDropAndCrop } from '@/common/components/file/CovasDropAndCrop';
const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            paddingTop: commonConsts.ui.headerHeight,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing.unit * 3,
            overflowX: 'auto',
        },
    });

interface Props extends WithStyles<typeof styles> {
    theme: Theme;
}

export const Component: React.SFC<Props> = (props) => {
    // Routes before sign in.
    const appContainer = UserAppContainer.useContainer();

    const onCropAndDropCallback = () => {
        appContainer.setValues({
            ...appContainer.values,
            cropAndDropProps: {
                ...appContainer.values.cropAndDropProps,
                visibility: 'hidden',
            },
        });
    };
    return (
        <div className="App">
            {appContainer.values.cropAndDropProps && appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? (
                <CovasDropAndCrop {...appContainer.values.cropAndDropProps} onClose={onCropAndDropCallback} visibility={'show'} dependAppContainer={UserAppContainer.useContainer} />
            ) : (
                <></>
            )}
            <div
                style={{ display: appContainer.values.cropAndDropProps && appContainer.values.cropAndDropProps.visibility && appContainer.values.cropAndDropProps.visibility === 'show' ? 'none' : '' }}
            >
                <LogoHeaderContainer.Provider>
                    <LogoHeader loadingState={appContainer.loadingState} />
                    <div className={props.classes.root}>
                        <main className={props.classes.content}>
                            <Switch>
                                <Route exact path={routes.auth.login.selectedWorkspace} component={components.auth.signin.List} />
                                <Route exact path={routes.auth.login.index} component={components.auth.signin.List} />
                                <Route exact path={routes.auth.logout.index} component={components.auth.logout.List} />
                                <Route exact path={routes.auth.recover.index} component={components.auth.recover.List} />
                                <Route exact path={routes.auth.signup.index} component={components.auth.verify.List} />
                            </Switch>
                        </main>
                    </div>
                </LogoHeaderContainer.Provider>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(Component);
