import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { CloseOutlined } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        modalHeader: {
            textAlign: 'center',
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
            backgroundColor: '#607D8B',
        },
        menuButton: {
            position: 'absolute',
            right: 0,
            textAlign: 'center',
        },
        heading: {
            margin: '0 auto',
            textAlign: 'center',
        },
    });

interface Props extends WithStyles<typeof styles> {
    title?: string;
    headerStyle?: any;
    modalCloseFunc: () => void;
}

export const Component: React.SFC<Props> = (props: Props) => {
    const { classes } = props;

    return (
        <>
            {props.headerStyle != null ? (
                <AppBar position="static" className={classes.modalHeader} style={props.headerStyle}>
                    <Toolbar variant="dense">
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Close" onClick={props.modalCloseFunc}>
                            <CloseOutlined />
                        </IconButton>
                        <Typography className={classes.heading} variant="h3" color="inherit">
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
            ) : (
                <AppBar position="static" className={classes.modalHeader}>
                    <Toolbar variant="dense">
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Close" onClick={props.modalCloseFunc}>
                            <CloseOutlined />
                        </IconButton>
                        <Typography className={classes.heading} variant="h3" color="inherit">
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
            )}
        </>
    );
};

export default withStyles(styles)(Component);
