import { default as React, useEffect } from 'react';
import { RouteComponentProps, withRouter, Prompt } from 'react-router';
import { default as UI } from '@/common/constants/ui';
import locale from '@/common/utils/locale';
import {
    Theme,
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    Grid,
    Paper,
    Select,
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    Chip,
    Switch,
    Modal,
    AppBar,
    Toolbar,
    IconButton,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { default as ChipInput } from 'material-ui-chip-input';
import { FileNameContainer } from './FileNameContainer';
import { separate, sequenceType, dateSeparate } from '@/user/constants/endpoint-setting';
import dummyNameRule from './dummyNameRule';
import { UserAppContainer } from '../../UserAppContainer';
import { isLoaded, isLoading, isError } from '@/common/components/hooks/useUI';
import * as errorHandler from '@/common/utils/errorHandler';
import usePreventWindowUnload from '@/common/components/hooks/usePreventWindowUnload';
import { Close } from '@material-ui/icons';
import Wordbook from '@/user/components/common/endpoints/wordbook/Wordbook';
import { getSplitNewLineString } from '@/common/utils/webappUtil';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '648px',
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        heading: {
            float: 'left',
            fontSize: '14px',
            color: '#666666',
        },
        example: {
            float: 'left',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#333333',
            overflow: 'auto',
            width: '100%',
            textAlign: 'left',
            paddingBottom: '5px',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
        fileNameErrorGrid: {
            textAlign: 'left',
            marginBottom: theme.spacing.unit * 2,
            overflowWrap: 'break-word',
            wordBreak: 'keep-all',
        },
        ruleContainer: {
            float: 'left',
            width: '100%',
            minHeight: '60px',
            borderRadius: '4px',
            border: '2px solid #bbbbbb',
            paddingTop: '10px',
            paddingLeft: '10px',
            textAlign: 'left',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            '&& p': {
                marginTop: '15px',
                marginBottom: '15px',
            },
        },
        extensionContainer: {
            float: 'left',
            width: '100%',
        },
        extension: {
            float: 'right',
            size: '14px',
            lineHeight: '20px',
        },
        formControl: {
            float: 'left',
            minWidth: theme.spacing.unit * 38,
            minHeight: theme.spacing.unit * 6.5,
            borderRadius: '4px',
            marginRight: '4%',
            width: '48%',
            '&& select': {
                paddingTop: theme.spacing.unit * 2,
                paddingBottom: theme.spacing.unit * 2.5,
                height: theme.spacing.unit * 3,
            },
        },
        select: {
            size: '15px',
            lineHeight: '26px',
            textAlign: 'left',
        },
        buttonFrame: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: '#ffffff',
            color: `${theme.palette.secondary.main}`,
            borderRadius: 6,
            height: '48px',
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ff7300',
                    backgroundColor: '#ffffff',
                },
            },
            '&:disabled': {
                backgroundColor: '#ff7300',
                color: '#ffffff',
            },
        },
        formControlLabel: {
            float: 'left',
            marginTop: '20px',
            marginBottom: '20px',
        },
        labelWordBook: {
            float: 'left',
            color: '#666666',
        },
        wordListContainer: {
            float: 'left',
            width: '100%',
            minHeight: '110px',
            borderRadius: '4px',
            border: '2px solid #bbbbbb',
            paddingTop: '10px',
            paddingLeft: '10px',
            textAlign: 'left',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.4em',
                height: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
            },
            [theme.breakpoints.down('xs')]: {
                minHeight: '256px',
            },
            '&& input': {
                paddingBottom: 80,
                [theme.breakpoints.down('xs')]: {
                    paddingBottom: 220,
                },
            },
            '&& p': {
                marginTop: '-15px',
                marginBottom: '15px',
            },
        },
        button: {
            minWidth: '196px',
            maxWidth: '246px',
            height: '44px',
            margin: `0 auto ${theme.spacing.unit * 3}px`,
            backgroundColor: `${theme.palette.secondary.main}`,
            borderRadius: '50px',
        },
        gridFormControl: {
            flexDirection: 'row',
        },
        switchLock: {
            float: 'right',
        },
        gridSwitch: {
            width: '100%',
        },
        labelSwitch: {
            float: 'left',
            color: '#666666',
            marginTop: theme.spacing.unit * 1.5,
            marginLeft: theme.spacing.unit * 2,
        },
        buttonSelected: {
            border: '1px solid #666666',
            backgroundColor: '#ffffff',
            color: '#666666',
            borderRadius: 6,
            height: '48px',
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ff7300',
                    backgroundColor: '#ffffff',
                },
            },
            '&:disabled': {
                border: '1px solid #666666',
                backgroundColor: '#ffffff',
                color: '#666666',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        buttonDateSelected: {
            borderRadius: 6,
            backgroundColor: '#ff7300',
            border: '1px solid #666666',
            color: 'white',
            height: '48px',
            marginRight: '8px',
            '&:disabled': {
                border: '1px solid #666666',
                backgroundColor: '#ffffff',
                color: '#666666',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        buttonTimeSelected: {
            border: '1px solid #ff7300',
            backgroundColor: '#ff7300',
            color: '#ffffff',
            borderRadius: 6,
            height: '48px',
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ffffff',
                    backgroundColor: '#ff7300',
                },
            },
            '&:disabled': {
                border: '1px solid #ff7300',
                backgroundColor: '#ff7300',
                color: '#ffffff',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        buttonTimeUnSelected: {
            border: '1px solid #ff7300',
            backgroundColor: '#ffffff',
            color: '#ff7300',
            borderRadius: 6,
            height: '48px',
            marginRight: '8px',
            '&:hover': {
                color: '#ffffff',
                '@media (hover: none)': {
                    color: '#ff7300',
                    backgroundColor: '#ffffff',
                },
            },
            '&:disabled': {
                border: '1px solid #666666',
                backgroundColor: '#ffffff',
                color: '#666666',
                borderRadius: 6,
                height: '48px',
                marginRight: '8px',
            },
        },
        commonModal: {
            backgroundColor: '#fff',
            flexGrow: 1,
            height: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            overflow: 'auto',
            outline: 'none',
        },
        header: {
            width: '100%',
            backgroundColor: '#607d8b',
        },
        headingModal: {
            margin: '0 auto',
            fontSize: theme.spacing.unit * 2,
            fontWeight: 'bold',
        },
        close: {
            fontSize: '28px',
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
        },
        openModalBtn: {
            textAlign: 'right',
            marginBottom: theme.spacing.unit * 2,
            '&& span': {
                fontWeight: 500,
                color: '#ff7300',
                fontSize: '15px',
            },
        },
        clearWordlist: {
            textAlign: 'left',
            marginBottom: theme.spacing.unit * 2,
            '&& span': {
                fontWeight: 500,
                color: '#666666',
                fontSize: '15px',
            },
        },
        chipContainer: {
            marginBottom: 0,
        },
        wordlistName: {
            backgroundColor: theme.palette.grey[200],
        },
        wordlistNameLine: {
            width: '75%',
            color: '#666666',
            fontSize: '15px',
        },
        wordlistSetting: {
            color: '#666666',
            fontSize: '15px',
        },
        wordlistAttention: {
            width: '130px',
            color: '#666666',
            fontSize: '15px',
        },
        wordlistNameError: {
            marginInlineStart: '4px',
            color: '#ff3d00',
            fontSize: '12px',
        },
    });

type TParams = { id: string };

interface Props extends WithStyles<typeof styles>, RouteComponentProps<TParams> {
    skipEffect?: boolean;
}

const FileNameComponent: React.SFC<Props> = (props) => {
    const { classes } = props;
    const fileNameContainer = FileNameContainer.useContainer();
    const appContainer = UserAppContainer.useContainer();
    fileNameContainer.setEndpointId(props.match.params.id);
    usePreventWindowUnload(fileNameContainer.isEdit);
    useEffect(() => {
        appContainer.updateLoadingState(fileNameContainer.ui);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileNameContainer.ui]);

    useEffect(() => {
        if (props.skipEffect) {
            fileNameContainer.loadDummyData(dummyNameRule);
            fileNameContainer.setUI(UI.state.Loaded);
            return;
        }
        void (async () => {
            try {
                fileNameContainer.setUI(UI.state.Loading);
                await fileNameContainer.getNameRule(props.match.params.id);
                fileNameContainer.setUI(UI.state.Loaded);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fileNameErrorMessage = () => {
        const splite = getSplitNewLineString(fileNameContainer.fileNameErrorMessage);
        const components: JSX.Element[] = [];
        for (let text of splite) {
            components.push(<Typography className={classes.wordlistNameError}>{text}</Typography>);
        }
        return components;
    };

    return (
        <>
            {isLoading(fileNameContainer.ui) && <div data-testid={UI.state.Loading} />}
            {isError(fileNameContainer.ui) && <div data-testid={UI.state.Error} />}
            {isLoaded(fileNameContainer.ui) && (
                <Grid container className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom className={classes.heading}>
                            {locale.t(locale.keys.fileName.rule.index)}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom className={classes.example}>
                            {locale.t(locale.keys.fileName.example.index)}
                            {fileNameContainer.getFileNameExample()}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.fileNameErrorGrid}>
                        {!fileNameContainer.buttonDisabled() ? <></> : fileNameErrorMessage()}
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.ruleContainer}>
                            <ChipInput
                                InputProps={{ id: 'namerule-chipinput' }}
                                disableUnderline={true}
                                classes={{ chipContainer: classes.chipContainer }}
                                value={fileNameContainer.itemList}
                                chipRenderer={({ value, handleDelete, className }, key) => {
                                    if (value === locale.t(locale.keys.fileName.date.index)) {
                                        return <Chip key={key} className={className} onDelete={handleDelete} label={fileNameContainer.handleInputDateButton()} tabIndex={-1} />;
                                    }
                                    const noFolder = Number(value.split(locale.t(locale.keys.fileName.folderLabel.index))[1]);
                                    if (!isNaN(noFolder) && noFolder >= 5) {
                                        return (
                                            <Chip
                                                key={key}
                                                className={className}
                                                onClick={() => fileNameContainer.handleClickChip(key, value)}
                                                color={fileNameContainer.itemSelected === key ? 'secondary' : 'default'}
                                                tabIndex={0}
                                                onDelete={handleDelete}
                                                label={value}
                                            />
                                        );
                                    }
                                    return (
                                        <Chip
                                            key={key}
                                            className={className}
                                            color={fileNameContainer.itemSelected === key ? 'secondary' : 'default'}
                                            onDelete={handleDelete}
                                            label={value}
                                            tabIndex={-1}
                                        />
                                    );
                                }}
                                onAdd={(chip: string) => fileNameContainer.handleAddItem(chip)}
                                onDelete={(chip: string, index: number) => fileNameContainer.handleDeleteItem(chip, index)}
                                error={fileNameContainer.fileNameLimitErrorMessege !== ''}
                                helperText={fileNameContainer.fileNameLimitErrorMessege}
                                fullWidth
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.extensionContainer}>
                            <Typography variant="body2" className={classes.extension}>
                                {locale.t(locale.keys.fileName.extension.index)}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.handleDisableFolder(1)}
                            onClick={() => {
                                fileNameContainer.handleClickFolderButton(1);
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.folder1.index)}
                        </Button>
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.handleDisableFolder(2)}
                            onClick={() => {
                                fileNameContainer.handleClickFolderButton(2);
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.folder2.index)}
                        </Button>
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.handleDisableFolder(3)}
                            onClick={() => {
                                fileNameContainer.handleClickFolderButton(3);
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.folder3.index)}
                        </Button>
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.handleDisableFolder(4)}
                            onClick={() => {
                                fileNameContainer.handleClickFolderButton(4);
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.folder4.index)}
                        </Button>
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.handleDisableFolder(5)}
                            onClick={() => {
                                fileNameContainer.handleClickFolderButton(5);
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.folder5.index)}
                        </Button>
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.anyCharaterFlag}
                            onClick={() => {
                                fileNameContainer.handleClickAnyCharaterButton();
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.anyCharater.index)}
                        </Button>
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.userFlag}
                            onClick={() => {
                                fileNameContainer.handleClickUserButton();
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.user.index)}
                        </Button>
                        <Button
                            className={classes.buttonFrame}
                            variant="contained"
                            disabled={fileNameContainer.dateFlag}
                            onClick={() => {
                                fileNameContainer.handleClickDateButton();
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.date.index)}
                        </Button>
                        <Button
                            className={!fileNameContainer.dateFlag ? classes.buttonSelected : fileNameContainer.isYearSelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                            variant="contained"
                            disabled={fileNameContainer.isButtonDisable('year')}
                            onClick={() => {
                                fileNameContainer.handleClickYearButton();
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.year.index)}
                        </Button>
                        <Button
                            className={!fileNameContainer.dateFlag ? classes.buttonSelected : fileNameContainer.isMonthSelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                            variant="contained"
                            disabled={fileNameContainer.isButtonDisable('month')}
                            onClick={() => {
                                fileNameContainer.handleClickMonthButton();
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.month.index)}
                        </Button>
                        <Button
                            className={!fileNameContainer.dateFlag ? classes.buttonSelected : fileNameContainer.isDaySelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                            variant="contained"
                            disabled={fileNameContainer.isButtonDisable('day')}
                            onClick={() => {
                                fileNameContainer.handleClickDayButton();
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.day.index)}
                        </Button>
                        <Button
                            className={!fileNameContainer.dateFlag ? classes.buttonSelected : fileNameContainer.isHourSelected ? classes.buttonTimeSelected : classes.buttonTimeUnSelected}
                            variant="contained"
                            disabled={fileNameContainer.isButtonDisable('hour')}
                            onClick={() => {
                                fileNameContainer.handleClickTimeButton();
                                fileNameContainer.handleDeleteFileNameLimitErrorMessege();
                            }}
                        >
                            {locale.t(locale.keys.fileName.time.index)}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            className={classes.formControlLabel}
                            control={<Checkbox checked={fileNameContainer.canEmpty} onChange={fileNameContainer.handleChangeCanEmpty} />}
                            label={locale.t(locale.keys.fileName.checkboxLabel.shareIndex)}
                        />
                    </Grid>

                    <Grid item container direction="column" spacing={32}>
                        {/* セパレーター */}
                        <Grid item container direction="column" spacing={16} className={classes.gridFormControl}>
                            <Grid item>
                                <Typography variant="body2" gutterBottom className={classes.heading}>
                                    {locale.t(locale.keys.fileName.separatorTitle)}
                                </Typography>
                            </Grid>
                            <Grid item container direction="row" spacing={16}>
                                <Grid item>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            className={classes.select}
                                            value={fileNameContainer.separatorSelected}
                                            onChange={fileNameContainer.handleChangeSeparatorSelected}
                                            input={<FilledInput />}
                                        >
                                            <MenuItem value="none">{locale.t(locale.keys.fileName.none.index)}</MenuItem>
                                            <MenuItem value={separate.DOT}>{locale.t(locale.keys.fileName.separatorDot.index)}</MenuItem>
                                            <MenuItem value={separate.DASH}>{locale.t(locale.keys.fileName.separatorHyphen.index)}</MenuItem>
                                            <MenuItem value={separate.UNDERSCORE}>{locale.t(locale.keys.fileName.separatorUnderscore.index)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl className={classes.formControl} disabled={!fileNameContainer.isDateSelected()}>
                                        <Select
                                            className={classes.select}
                                            value={fileNameContainer.dateSeparatorSelected}
                                            onChange={fileNameContainer.handleChangeDateSeparatorSelected}
                                            input={<FilledInput />}
                                        >
                                            <MenuItem value={dateSeparate.NONE}>{locale.t(locale.keys.fileName.dateSeparator.none)}</MenuItem>
                                            <MenuItem value={dateSeparate.DOT}>{locale.t(locale.keys.fileName.dateSeparator.dot)}</MenuItem>
                                            <MenuItem value={dateSeparate.HYPHEN}>{locale.t(locale.keys.fileName.dateSeparator.hyphen)}</MenuItem>
                                            <MenuItem value={dateSeparate.UNDERSCORE}>{locale.t(locale.keys.fileName.dateSeparator.underscore)}</MenuItem>
                                            <MenuItem value={dateSeparate.C}>{locale.t(locale.keys.fileName.dateSeparator.c)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* 複数枚原稿連番付け */}
                        <Grid item container direction="column" spacing={16} className={classes.gridFormControl}>
                            <Grid item>
                                <Typography variant="body2" gutterBottom className={classes.heading}>
                                    {locale.t(locale.keys.fileName.severalPagesTitle)}
                                </Typography>
                            </Grid>

                            <Grid item container direction="row" spacing={16}>
                                <Grid item>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            data-testid={'sequenceTypeSelected'}
                                            className={classes.select}
                                            value={fileNameContainer.sequenceTypeSelected}
                                            onChange={fileNameContainer.handleChangeSequenceTypeSelected}
                                            input={<FilledInput />}
                                        >
                                            <MenuItem value={sequenceType.COUNT}>{locale.t(locale.keys.fileName.severalPagesCount.index)}</MenuItem>
                                            <MenuItem value={sequenceType.PUT}>{locale.t(locale.keys.fileName.severalPagesPut.index)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.gridSwitch}>
                        <div className={classes.switchLock}>
                            <Typography variant="body2" gutterBottom className={classes.labelSwitch}>
                                {locale.t(locale.keys.fileName.switchBlock.index)}
                            </Typography>
                            <Switch onChange={() => fileNameContainer.handleChangeSwitch()} inputProps={{ 'aria-label': 'secondary checkbox' }} checked={fileNameContainer.isLock} />
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className={classes.extensionContainer}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item container alignItems="center" direction="row" justify="flex-start" className={classes.wordlistNameLine}>
                                    <Typography className={classes.labelWordBook}>{locale.t(locale.keys.fileName.wordbook.prefix)}</Typography>
                                    <TextField
                                        value={fileNameContainer.wordListName01}
                                        className={classes.wordlistName}
                                        inputProps={{ maxlength: 33, 'data-testid': 'wordlistname1-input' }}
                                        error={fileNameContainer.errorsName.wordlistname !== ''}
                                        onChange={(evt) => {
                                            fileNameContainer.handleChangeWordListName01(evt.target.value);
                                        }}
                                    />
                                    {fileNameContainer.errorsName.wordlistname ? <Typography className={classes.wordlistNameError}>{fileNameContainer.errorsName.wordlistname}</Typography> : <></>}
                                </Grid>
                                <Grid item alignItems="center" justify="flex-end" className={classes.wordlistAttention}>
                                    <Typography style={{ textAlign: 'right', color: '#666666' }}>{locale.t(locale.keys.fileName.workBook.index)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.wordListContainer}>
                            <ChipInput
                                disableUnderline={true}
                                classes={{ chipContainer: classes.chipContainer }}
                                value={fileNameContainer.wordList}
                                onAdd={(chip: string) => fileNameContainer.handleAddWord(chip)}
                                onDelete={(chip: string) => fileNameContainer.handleDeleteWord(chip)}
                                fullWidth
                                error={fileNameContainer.errors.wordlist !== ''}
                                helperText={fileNameContainer.errors.wordlist}
                                chipRenderer={({ value, handleDelete, className }, key) => {
                                    return value ? <Chip key={key} className={className} onDelete={handleDelete} label={value} tabIndex={-1} /> : '';
                                }}
                            />
                        </Paper>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item className={classes.clearWordlist}>
                                <Button onClick={fileNameContainer.clearWordList}>{locale.t(locale.keys.fileName.clearWordList)}</Button>
                            </Grid>
                            <Grid item className={classes.openModalBtn}>
                                <Button onClick={fileNameContainer.handleOpen}>{locale.t(locale.keys.fileName.wordbook.title)}</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className={classes.extensionContainer}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item container alignItems="center" direction="row" justify="flex-start" className={classes.wordlistNameLine}>
                                    <Typography className={classes.labelWordBook}>{locale.t(locale.keys.fileName.wordbook.prefix)}</Typography>
                                    <TextField
                                        value={fileNameContainer.wordListName02}
                                        className={classes.wordlistName}
                                        inputProps={{ maxlength: 33 }}
                                        error={fileNameContainer.errorsName.wordlistname2 !== ''}
                                        onChange={(evt) => {
                                            fileNameContainer.handleChangeWordListName02(evt.target.value);
                                        }}
                                    />
                                    {fileNameContainer.errorsName.wordlistname2 ? <Typography className={classes.wordlistNameError}>{fileNameContainer.errorsName.wordlistname2}</Typography> : <></>}
                                </Grid>
                                <Grid item alignItems="center" justify="flex-end" className={classes.wordlistAttention}>
                                    <Typography style={{ textAlign: 'right', color: '#666666' }}>{locale.t(locale.keys.fileName.workBook.index)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.wordListContainer}>
                            <ChipInput
                                disableUnderline={true}
                                classes={{ chipContainer: classes.chipContainer }}
                                value={fileNameContainer.wordList02}
                                onAdd={(chip: string) => fileNameContainer.handleAddWord2(chip)}
                                onDelete={(chip: string) => fileNameContainer.handleDeleteWord2(chip)}
                                fullWidth
                                error={fileNameContainer.errors.wordlist2 !== ''}
                                helperText={fileNameContainer.errors.wordlist2}
                                chipRenderer={({ value, handleDelete, className }, key) => {
                                    return value ? <Chip key={key} className={className} onDelete={handleDelete} label={value} tabIndex={-1} /> : '';
                                }}
                            />
                        </Paper>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                            <Grid item className={classes.clearWordlist}>
                                <Button onClick={fileNameContainer.clearWordList2}>{locale.t(locale.keys.fileName.clearWordList)}</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button className={classes.button} fullWidth variant="contained" disabled={fileNameContainer.buttonDisabled()} onClick={fileNameContainer.handleClickSave}>
                            {locale.t(locale.keys.fileName.saveChanges.index)}
                        </Button>
                    </Grid>
                    <Prompt when={fileNameContainer.isEdit} message={locale.t(locale.keys.promptMessage)} />
                    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={fileNameContainer.open} onClose={fileNameContainer.handleClose}>
                        <div className={classes.commonModal}>
                            <AppBar position="static" className={classes.header}>
                                <Toolbar variant="dense">
                                    <Typography color="inherit" className={classes.headingModal}>
                                        {locale.t(locale.keys.fileName.wordbook.title)}
                                    </Typography>
                                    <IconButton color="inherit" className={classes.close} onClick={fileNameContainer.handleClose}>
                                        <Close />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Wordbook closeModal={fileNameContainer.handleClose} wordbooks={fileNameContainer.replaceWordbook} />
                        </div>
                    </Modal>
                </Grid>
            )}
        </>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(FileNameComponent));
