import { default as React } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Theme, WithStyles, withStyles, Toolbar, AppBar, IconButton, Typography, Grid, Tooltip, ClickAwayListener } from '@material-ui/core';
import { ArrowBack, MoreVert, InfoOutlined } from '@material-ui/icons';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import DashboardHeader from '@/user/components/common/headers/DashboardHeader';
import LoadingState from '@/common/components/ui-state/LoadingState';
import genericHeader from '@/common/css/genericHeader';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import { agentSigninHeaderHeight } from '@/user/components/common/headers/AgentSigninHeader';

interface Props extends WithStyles<typeof genericHeader>, RouteComponentProps {
    title?: string;
    pageDescription?: string;
    theme: Theme;
    onMenuOpen: () => void;
}

export const Component: React.FC<Props> = (props: Props) => {
    const [isPageDescOpen, setPageDescOpen] = React.useState(false);
    const matchesBreakPoint = useMediaQuery(props.theme.breakpoints.up('lg'));
    const matchesSmallBreakPoint = useMediaQuery(props.theme.breakpoints.up('md'));
    const appContainer = UserAppContainer.useContainer();
    const appBarClasses = props.pageDescription ? props.classes.descheader : props.classes.header;
    const render = () => {
        if (matchesBreakPoint) {
            return <DashboardHeader onMenuOpen={props.onMenuOpen} appContainerValues={appContainer.values} />;
        }
        return (
            <AppBar position="static" className={appBarClasses} style={{ backgroundColor: '#607d8b', top: agentSigninHeaderHeight() }}>
                <Toolbar variant="dense">
                    <IconButton className={props.classes.menuButton} color="inherit" aria-label="Menu" onClick={props.onMenuOpen}>
                        <MoreVert />
                    </IconButton>

                    <IconButton className={props.classes.arrowBackButton} color="inherit" aria-label="Close" onClick={() => props.history.goBack()}>
                        <ArrowBack />
                    </IconButton>
                    <Grid container direction={matchesSmallBreakPoint ? 'column' : 'row'} justify="center" alignItems="center">
                        <Typography className={props.classes.heading} variant="h4" color="inherit">
                            {props.title}
                        </Typography>
                        {props.pageDescription &&
                            (matchesSmallBreakPoint ? (
                                props.pageDescription.split('\n').map((value, index) => {
                                    return (
                                        <Typography key={index} variant="caption" color="inherit">
                                            {value}
                                        </Typography>
                                    );
                                })
                            ) : (
                                // 小画面（幅960px未満）はページ説明が幅をはみ出るので省略
                                <ClickAwayListener onClickAway={() => setPageDescOpen(false)}>
                                    <Tooltip
                                        title={
                                            <>
                                                {props.pageDescription &&
                                                    props.pageDescription.split('\n').map((value, index) => {
                                                        return (
                                                            <Typography key={index} variant="caption" color="inherit">
                                                                {value}
                                                            </Typography>
                                                        );
                                                    })}
                                            </>
                                        }
                                        onClose={() => setPageDescOpen(false)}
                                        open={isPageDescOpen}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        classes={{ tooltip: props.classes.lightTooltip, popper: props.classes.popper }}
                                    >
                                        <IconButton onClick={() => setPageDescOpen(!isPageDescOpen)} className={props.classes.tooltipButton}>
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </ClickAwayListener>
                            ))}
                    </Grid>
                </Toolbar>
                {appContainer.loadingState && <LoadingState isDesc={props.pageDescription ? true : false} />}
            </AppBar>
        );
    };

    return <>{render()}</>;
};

export default withStyles(genericHeader, { withTheme: true })(withRouter(Component));
