import { default as React } from 'react';
import { Button } from '@material-ui/core';
import queryString from 'query-string';

import * as models from '@/user/models/cloud-connection/cloud-connection';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { identifyUser } from '@/user/models/auth';
import { UserAppContainer } from '../UserAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import shareFormat from '@/common/constants/shareFormat';

interface IWindow extends Window {
    authorize?: string;
}
interface Props {
    window: IWindow;
    url: string;
    id: string;
    callBack: (storageId: string, dueDate: string, status: string, authorizationId: string) => void;
    classes: string;
    disable: boolean;
}
const PopupAction = (props: Props) => {
    const authorFuncion = () => {
        // open popup for author
        const popup = window.open(props.url, 'Authorization', 'height=682,width=744');
        if (popup) {
            handleClosePopUp(popup, props.id);
        }
    };
    // user_sub
    const sub = identifyUser()!.sub;
    const appContainer = UserAppContainer.useContainer();
    const handleClosePopUp = async (popup: Window, id: string) => {
        const intervalObj = setInterval(async () => {
            if (null != popup && popup.closed) {
                clearInterval(intervalObj);
                await updateCloud(id);
            }
        }, 1000);
    };
    const updateCloud = async (id: string) => {
        try {
            if (props.window !== null && props.window.authorize) {
                const obj = queryString.parse(props.window.authorize);
                let code: string = '';
                if (typeof obj.code === 'string') {
                    code = obj.code;
                }
                const req: schema.V1XStoragesUpdateRequest = {
                    authorCode: code,
                    share: shareFormat.personal,
                };
                props.window.authorize = '';
                // call to update API
                const response = await models.updateUserCloud(sub, id, req, appContainer.values.authorizationCode);
                // call to update API
                props.callBack(response.xstorageId, response.authorizationDeadline, response.status, response.authorizationId);
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };
    return (
        <>
            <Button disabled={props.disable} className={props.classes} onClick={authorFuncion}>
                {locale.t(locale.keys.cloudConnection.button.connectAuthorize)}
            </Button>
        </>
    );
};

export default PopupAction;
