import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Theme, createStyles, withStyles, WithStyles, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import constants from '@/common/constants';
import routes from '@/user/constants/routes';
import * as locale from '@/common/utils/locale/locale';
import { UserAppContainer } from '@/user/components/UserAppContainer';
import userRole from '@/common/constants/userRole';
import environment from '@/common/constants/environment';
import * as auth from '@/common/utils/authManagement/authUtil';
import HeaderMenuListItems from '@/common/components/HeaderMenuListItems';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            '&& div': {
                backgroundColor: 'transparent',
            },
        },
        drawer: {
            [theme.breakpoints.up('lg')]: {
                borderRight: '1px solid',
                borderColor: theme.palette.grey[400],
                height: `calc(100vh - ${constants.ui.headerHeight}px)`,
                width: constants.ui.drawerWidth + 16,
                flexShrink: 0,
            },
            backgroundColor: '#e3eaed',
        },
        list: {
            // このaタグのcolorを黒くするの全体で指定していいかも
            '&& a': {
                color: theme.palette.text.primary,
            },
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            overflowX: 'auto',
            [theme.breakpoints.down('md')]: {
                minHeight: '50vh',
                maxHeight: '50vh',
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: '95vh',
            },
            '&& li': {
                height: '48px',
            },
            '&& hr': {
                marginBottom: '6px',
                marginTop: '6px',
            },
            borderRight: '1px solid',
            borderColor: '#bdbdbd',
            minHeight: '95vh',
        },
        paper: {
            borderRadius: 5,
            position: 'fixed',
            top: 60,
            right: theme.spacing.unit,
            width: '80%',
            minWidth: 250,
            maxWidth: 250,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            outline: 'none',
            transition: '0.2s',
        },
        nested: {},
        navItem: {
            fontSize: '16px',
            lineHeight: 3,
            color: '#333333',
            borderRadius: '4px',
        },
        context: {
            letterSpacing: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            '&& li': {
                marginTop: '13px',
                height: '12px',
            },
            '&& span': {
                fontSize: '12px',
                lineHeight: 'normal',
                color: '#666666',
            },
        },
        exampleItem: {
            height: '48px!important',
        },
    });

const CustomListItem = withStyles({
    root: {
        '&$selected': {
            backgroundColor: '#f2f4f5',
        },
    },
    selected: {},
})(ListItem);

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    isAdminUser: boolean;
    theme: Theme;
    commonMenuOpen: boolean;
    commonMenuCloseFunc: () => void;
}

const isSelected = (refs: string) => {
    return refs === window.location.pathname;
};

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;
    const appContainer = UserAppContainer.useContainer();
    const matchesBreakPoint = useMediaQuery(props.theme.breakpoints.up('lg'));

    const handClose = () => {
        props.commonMenuCloseFunc();
    };
    const handleWebappLinkClick = () => {
        auth.setShareAuth({
            token: appContainer.values.authorizationCode,
            workspaceUserId: appContainer.values.signinWorkspaceUserObject.id!,
        });
        handClose();
    };
    return (
        <>
            <List component="nav" className={classes.list}>
                <CustomListItem key="dashboard" onClick={handClose} className={classes.navItem} selected={isSelected(routes.dashboard.index)}>
                    <Link to={routes.dashboard.index}>{locale.t(locale.keys.pageTitle.dashboard.userIndex)}</Link>
                </CustomListItem>
                <div className={classes.context}>
                    <ListItem>
                        <ListItemText>{locale.t(locale.keys.pageTitle.spool.index)}</ListItemText>
                    </ListItem>
                </div>
                <CustomListItem key="dashboard-item" onClick={handClose} className={classes.navItem} selected={isSelected(routes.dashboard.spool)}>
                    <Link to={routes.dashboard.spool}>{locale.t(locale.keys.pageTitle.spool.index)}</Link>
                </CustomListItem>
                <div className={classes.context}>
                    <ListItem key="setting-item">
                        <ListItemText key="setting-text">{locale.t(locale.keys.pageTitle.setting.index)}</ListItemText>
                    </ListItem>
                </div>
                <div className={classes.navItem}>
                    <CustomListItem key="profile-item" onClick={handClose} selected={isSelected(routes.userProfile.index)}>
                        <Link to={routes.userProfile.index}>{locale.t(locale.keys.pageTitle.userProfile.index)}</Link>
                    </CustomListItem>
                    <CustomListItem onClick={handClose} selected={isSelected(routes.cloudConnection.index)}>
                        <Link to={routes.cloudConnection.index}>{locale.t(locale.keys.pageTitle.cloudStorage.index)}</Link>
                    </CustomListItem>
                    <CustomListItem onClick={handClose} selected={isSelected(routes.endpoints.index)}>
                        <Link to={routes.endpoints.index}>{locale.t(locale.keys.pageTitle.endpoint.index)}</Link>
                    </CustomListItem>
                    {/* 共有クラウドストレージ */
                    appContainer.values.signinWorkspaceUserObject.role === userRole.admin ? (
                        <CustomListItem onClick={handClose} selected={isSelected(routes.sharedCloudConnection.index)}>
                            <Link to={routes.sharedCloudConnection.index}>{locale.t(locale.keys.pageTitle.sharedCloudStorage.index)}</Link>
                        </CustomListItem>
                    ) : null}
                    <CustomListItem onClick={handClose} selected={isSelected(routes.sharedEndpoints.index)}>
                        <Link to={routes.sharedEndpoints.index}>{locale.t(locale.keys.pageTitle.sharedEndpoint.index)}</Link>
                    </CustomListItem>

                    <CustomListItem onClick={handClose} selected={isSelected(routes.mfp.index)}>
                        <Link to={routes.mfp.index}>{locale.t(locale.keys.pageTitle.sharedDevice.index)}</Link>
                    </CustomListItem>
                </div>
                {appContainer.values.signinWorkspaceUserObject.role !== userRole.member &&
                    (appContainer.values.signinWorkspaceUserObject.role === userRole.admin ||
                        appContainer.values.signinWorkspaceUserObject.role === userRole.systemAdmin ||
                        appContainer.values.signinWorkspaceObject.enableSubWorkspace === true) && (
                        <>
                            <div className={classes.context}>
                                <ListItem>
                                    <ListItemText>{locale.t(locale.keys.common.covasManagementSite)}</ListItemText>
                                </ListItem>
                            </div>
                            <div className={classes.navItem}>
                                <CustomListItem
                                    button
                                    component="a"
                                    onClick={handleWebappLinkClick}
                                    target="covas_webapp_admin"
                                    href={`https://${environment.webapp.admin.hostname!}/auth/login/${appContainer.values.signinWorkspaceObject.displayId}`}
                                    style={{ height: '48px' }}
                                >
                                    <ListItemIcon style={{ marginRight: '8px', color: '#333' }}>
                                        <Launch />
                                    </ListItemIcon>
                                    {locale.t(locale.keys.common.covasManagementConsole)}
                                </CustomListItem>
                            </div>
                        </>
                    )}
                <div className={classes.navItem}>
                    {!matchesBreakPoint && (
                        <>
                            <Divider />
                            <HeaderMenuListItems
                                isAdminApp={false}
                                signoutPath={routes.auth.logout.index}
                                name={appContainer.values.signinWorkspaceUserObject.name}
                                phoneticName={appContainer.values.signinWorkspaceUserObject.phoneticName}
                                email={appContainer.values.signinWorkspaceUserObject.invitationEmail}
                                avatarUrl={appContainer.values.signinWorkspaceUserObject.avatarUrl}
                                commonMenuCloseFunc={props.commonMenuCloseFunc}
                            />
                        </>
                    )}
                </div>
            </List>
        </>
    );
};

export default withRouter(withStyles(styles, { withTheme: true })(Component));
