import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, AppBar, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import ui from '@/common/constants/ui';
import LoadingState from '@/common/components/ui-state/LoadingState';
import { LogoHeaderContainer } from '@/common/components/headers/LogoHeaderContainer';
import logger from '@/common/utils/logger';
import constants from '@/common/constants';

export const styles = (theme: Theme) =>
    createStyles({
        header: {
            height: ui.headerHeight,
            position: 'fixed',
            borderBottom: `2px solid ${theme.palette.grey[300]}`,
            justifyContent: 'center',
            maxWidth: constants.ui.maxWidthScreen,
        },
        heading: {
            color: '#7e7e7e',
        },
        logo: {
            marginTop: '4px',
            width: ui.logoWidth,
        },
        arrowBackButton: {
            position: 'absolute',
            left: 0,
        },
    });

interface Props extends WithStyles<typeof styles> {
    loadingState: boolean;
}

export const Component: React.FC<Props> = (props: Props) => {
    const lhc = LogoHeaderContainer.useContainer();
    logger.debug(lhc);
    return (
        <>
            <AppBar position="static" color="inherit" className={props.classes.header}>
                {lhc.backFunc && (
                    <IconButton className={props.classes.arrowBackButton} color="inherit" onClick={() => lhc.backFunc && lhc.backFunc()}>
                        <ArrowBack />
                    </IconButton>
                )}
                <div>
                    <img className={props.classes.logo} alt="header-logo" src="/assets/images/logo/brand.svg" />
                </div>
                {props.loadingState && <LoadingState />}
            </AppBar>
        </>
    );
};

export default withStyles(styles)(Component);
