import { Printer } from '@/user/components/mfp/printerList';
import { IMessage, Variants } from '@/common/components/messages/CommonMessage';
import { default as UI } from '@/common/constants/ui';
import { State } from '@/common/components/hooks/useUI';
import * as schema from '@/schema/typescript/schema';

export interface MFP {
    id: string;
    name: string;
    model: string;
    number: string;
    groups?: schema.V1ObjectsGroup[];
}

export interface IMFP {
    loginPageModal: boolean;
    openLoginPageModal: () => void;
    closeLoginPageModal: () => void;
    inputPin: string;
    pinFormDisable: boolean;
    changePin: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | null) => void;
    changeUpdatePin: (index: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | null) => void;
    selectIndex: number;
    activePrinter: {
        id: string;
        name: string;
        model: string;
        number: string;
    };
    User: {
        sub: string;
        email: string;
        mfpNumber: string;
    };
    printerList: Printer[];
    handleActivePrinter: (id: string) => void;
    activePrinterOpen: boolean;
    toggleActivePrinter: () => void;
    mfp: MFP;
    registerMFP: (printer: any) => void;
    mfpList: MFP[];
    mfpListModal: boolean;
    openMFPListModal: () => void;
    closeMFPListModal: () => void;
    collapseNumber: number | null;
    handleCollapseNumber: (collapseNumber: number | null, index: number) => void;
    isCollapse: (collapseNumber: number | null, index: number) => boolean;
    getDefaultPin: (mfplist: any, index: number) => number;
    updatePin: (index: number) => void;
    removeMFP: (index: number) => void;
    cancelCourse: () => void;
    getMfps: (token?: string) => void;
    updateUnLocked: (id: string, index: number) => void;
    confirmRemove: (isYes: boolean, value?: string) => void;
    message: IMessage;
    openAddNew: boolean;
    isLocked: boolean;
    defaultPinValue: string;
    handleSaveClick: () => void;
    handleUnlockDevices: () => void;
    isOpen: boolean;
    textMessageSave: string;
    textMessageDelete: string;
    uiState: string;
    getStateObject: (uiString: string) => State;
    mfpUI: string;
    isEdit: boolean;
    handleChangeExpanded: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    expanded: string | false;
    handleChange: () => void;
}

export const defaultValue: IMFP = {
    loginPageModal: false,
    openLoginPageModal: () => {},
    closeLoginPageModal: () => {},
    inputPin: '',
    pinFormDisable: true,
    changePin: () => {},
    changeUpdatePin: () => () => {},
    selectIndex: -1,
    activePrinter: {
        id: '',
        name: '',
        model: '',
        number: '',
    },
    User: {
        sub: '',
        email: '',
        mfpNumber: '',
    },
    printerList: [],
    handleActivePrinter: () => {},
    activePrinterOpen: false,
    toggleActivePrinter: () => {},
    mfp: {
        id: '0',
        name: 'xxxxx',
        model: 'xxxxx',
        number: 'xxxxx',
        groups: [],
    },
    registerMFP: () => {},
    mfpList: [],
    mfpListModal: true,
    openMFPListModal: () => {},
    closeMFPListModal: () => {},
    collapseNumber: null,
    handleCollapseNumber: () => {},
    isCollapse: () => true,
    getDefaultPin: () => 0,
    updatePin: () => {},
    removeMFP: () => {},
    cancelCourse: () => {},
    getMfps: () => {},
    updateUnLocked: () => {},
    confirmRemove: () => {},
    message: {
        isOpen: false,
        message: '',
        variant: Variants.info,
    },
    openAddNew: false,
    isLocked: false,
    defaultPinValue: '567890',
    handleSaveClick: () => {},
    handleUnlockDevices: () => {},
    isOpen: false,
    textMessageSave: '',
    textMessageDelete: '',
    uiState: UI.state.Loading,
    getStateObject: () => State.Loading,
    mfpUI: '',
    isEdit: false,
    handleChangeExpanded: () => () => {},
    expanded: false,
    handleChange: () => {},
};
