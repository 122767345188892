/**
 * Route definitions.
 * Heavily inspired by Rails routing.
 * https://guides.rubyonrails.org/routing.html#crud-verbs-and-actions
 */

const routes = {
    announcement: {
        index: '/announcement',
    },
    auth: {
        // /auth以下は全て認証なしでアクセスできる画面
        index: '/auth',
        login: {
            index: '/auth/login/',
            // /auth/loginと役割がかぶっている。本来はワークスペース選択後のパスワード認証画面用であるが、実際は/auth/loginをそのまま利用している状態である（/auth/login/:idでもパスワード認証ができることはできる。）。
            selectedWorkspace: '/auth/login/:id',
        },
        logout: {
            index: '/auth/logout',
        },
        recover: {
            index: '/auth/recover',
        },
        verify: {
            index: '/auth/verify',
        },
        signup: {
            index: '/auth/signup',
        },
        workspaceSignup: {
            index: '/auth/workspace-signup',
        },
    },
    analytics: {
        index: '/analytics',
    },
    dashboard: {
        index: '/',
    },
    user: {
        index: '/user',
        edit: '/user/:id',
        invite: {
            index: '/user/invite',
        },
        collective: {
            index: '/user/collective-invite',
        },
        // 過去「/member」でアクセスしていたページをリダイレクトするためのルート
        old: {
            index: '/member',
            edit: '/member/:id',
            invite: {
                index: '/member/invite',
            },
            collective: {
                index: '/member/collective-invite',
            },
        },
    },
    authManagement: {
        index: '/auth-management',
        detail: '/auth-management/detail/:orderId',
    },
    workspace: {
        index: '/workspace',
    },
    subWorkspace: {
        index: '/sub-workspace',
        new: '/sub-workspace/new',
        edit: '/sub-workspace/detail/:id',
        mfps: '/sub-workspace/mfps',
        invite: {
            index: '/sub-workspace/invite',
        },
    },
    entry: {
        index: '/entry',
        detail: '/entry/detail/:id',
    },
    device: {
        index: '/device',
        new: '/device/new',
        edit: '/device/:id',
        collective: {
            index: '/device/collective-register',
        },
    },
    registrationEndpoint: {
        index: '/endpoints/collective-register',
    },
    cloudSetting: {
        index: '/cloud-setting',
        edit: '/cloud-setting/:id',
    },
    forbidden: {
        index: '/forbidden',
    },
};

export default routes;
