import * as React from 'react';
import { Button, createStyles, MenuItem, Select, Theme, WithStyles, withStyles } from '@material-ui/core';

import queryString from 'query-string';
import Cancel from '@/common/components/Button/Cancel';
import { State as UI } from '@/common/components/hooks/useUI';

import { useValue } from './useUI';
import * as models from '@/user/models/cloud-connection/cloud-connection';
import * as serviceModels from '@/user/models/workspaces/services/services';
import locale from '@/common/utils/locale';
import * as schema from '@/bundles/schema/typescript/schema';
import { Variants } from '@/common/components/messages/CommonMessage';
import { UserAppContainer } from '../../UserAppContainer';
import * as errorHandler from '@/common/utils/errorHandler';
import * as webappUtil from '@/common/utils/webappUtil';
import shareFormat from '@/common/constants/shareFormat';
import { StatusKey } from '@/common/constants/http-status';

const styles = (theme: Theme) =>
    createStyles({
        mb3x: {
            marginBottom: theme.spacing.unit * 3,
        },
        box: {
            backgroundColor: '#eee',
            borderTopLeftTadius: 4,
            borderTopRightRadius: 4,
            marginBottom: theme.spacing.unit * 3,
            width: '100%',
            '&&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.13)',
            },
            '&& div': {
                paddingLeft: 4,
                paddingRight: 4,
                fontSize: '1.125rem',
            },
        },
        mail: {
            '&& label': {
                fontWeight: 'normal',
                fontStyle: 'normal',
                fontStretch: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                color: '#666666',
            },
        },
        title: {
            color: theme.palette.grey[700],
        },
        button: {
            '&& span': {
                fontSize: '1.25rem',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '0.875rem',
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: '146px',
            },
        },
        buttonCase: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        label: {
            fontSize: '0.875rem',
            margin: 0,
            padding: `${theme.spacing.unit}px ${theme.spacing.unit * 1.5}px 0`,
            color: '#666666',
        },
        select: {
            '&& svg': {
                marginTop: '-10px',
            },
        },
    });
interface IWindow extends Window {
    authorize?: string;
}
interface Props extends WithStyles<typeof styles> {
    onClose: () => void;
    admit?: (object: schema.V1XStoragesUpdateResponse) => void;
    window?: IWindow;
}

export const Component: React.FC<Props> = (props: Props) => {
    const { classes } = props;

    const compValueUI = useValue();
    const [serviceList, updateServiceList] = React.useState();
    const appContainer = UserAppContainer.useContainer();

    const admitPush = async () => {
        try {
            const service = webappUtil.getEnumData(compValueUI.values.cloud, schema.V1ObjectsServiceEnum);
            // No register with Nonecloud and email xstorage
            if ([schema.V1ObjectsServiceEnum.Nonecloud, schema.V1ObjectsServiceEnum.Email].includes(service)) {
                return;
            }
            // authorize in here
            if (!props.window) {
                return;
            }
            // open popup for author
            const popup = window.open('', 'Authorization', 'height=682,width=744');
            if (popup) {
                handleClosePopUp(popup, service);
            }
            const response = await models.getAuthorLink(service, appContainer.values.authorizationCode);
            if (!response) {
                props.onClose();
            }
            if (popup) {
                // update href for popup
                popup.location.href = response.authorizationLink;
            }
        } catch (e) {
            errorHandler.handleApiError(appContainer, e);
        }
    };

    const handleClosePopUp = async (popup: Window, service: schema.V1ObjectsServiceEnum) => {
        const intervalObj = setInterval(async () => {
            if (null != popup && popup.closed) {
                clearInterval(intervalObj);
                await createCloud(service);
            }
        }, 1000);
    };
    const generateUserCloud = async (service: schema.V1ObjectsServiceEnum, code: string) => {
        let authorCode = code;
        if (service === schema.V1ObjectsServiceEnum.Docab || service === schema.V1ObjectsServiceEnum.Docard) {
            authorCode = decodeURIComponent(code);
        }
        const reqObj: schema.V1XStoragesCreateRequest = {
            service,
            authorCode,
            share: shareFormat.share,
        };
        const response = await models.createUserCloud(reqObj, appContainer.values.authorizationCode);
        if (typeof props.admit === 'function' && response.xstorageId) {
            const object: schema.V1XStoragesUpdateResponse = {
                authorizationDeadline: response.authorizationDeadline,
                authorizationId: response.authorizationId,
                status: response.status,
                authorizationLink: response.authorizationLink,
                xstorageId: response.xstorageId,
                service: response.service,
                imageLink: response.imageLink,
            };
            props.admit(object);
        }
        props.onClose();
    };
    const createCloud = async (service: schema.V1ObjectsServiceEnum) => {
        try {
            if (props.window && props.window.authorize) {
                const obj = queryString.parse(props.window.authorize);
                let code: string = '';
                if (typeof obj.code === 'string') {
                    code = obj.code;
                }
                props.window.authorize = '';
                // call to create API
                await generateUserCloud(service, code);
            }
        } catch (e) {
            props.onClose();
            errorHandler.handleApiError(appContainer, e);
        }
    };

    React.useEffect(() => {
        if (serviceList) {
            return;
        }
        (async () => {
            try {
                const cloudList = await serviceModels.getServices(appContainer.values.signinWorkspaceObject.id || '', appContainer.values.authorizationCode);
                const list = [];
                for (const i in cloudList.settings) {
                    const item = cloudList.settings[i];
                    // check 3L flag
                    if (!item.is3L) {
                        continue;
                    }
                    // No create with cloud email
                    if ([schema.EndpointType.Email].includes(item.serviceName)) {
                        continue;
                    }
                    const name = webappUtil.getServiceText(item.serviceName);
                    list.push(
                        <MenuItem key={i} value={item.serviceName}>
                            {name}
                        </MenuItem>,
                    );
                }
                updateServiceList(list);
            } catch (e) {
                errorHandler.handleApiError(appContainer, e);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={classes.box} data-testid={UI.Loaded}>
                <p className={classes.label}>{locale.t(locale.keys.cloudConnection.modal.label.service)}</p>
                <Select
                    className={classes.select}
                    value={compValueUI.values.cloud}
                    onChange={compValueUI.handleChange}
                    fullWidth
                    inputProps={{
                        name: 'cloud',
                        id: 'cloud-simple',
                    }}
                >
                    {serviceList}
                </Select>
            </div>
            {/* <div className={classes.mb3x}>
                {
                    // 以下フィールドにはメアドしか入らない？現状バリデーション未作成
                }
                <TextField
                    onChange={mailUI.mailInput}
                    className={classes.mail}
                    variant="filled"
                    label={locale.t(locale.keys.cloudConnection.label.authorizationId)}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div> */}
            <div className={classes.buttonCase}>
                <Cancel cancelFunc={props.onClose} />
                <Button disabled={compValueUI.values.cloud ? false : true} variant="contained" fullWidth onClick={admitPush} className={classes.button}>
                    {locale.t(locale.keys.cloudConnection.modal.button.authorize)}
                </Button>
            </div>
        </>
    );
};

export default withStyles(styles)(Component);
